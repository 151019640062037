.footer {
    // height: rem-calc(442);
    // height: rem-calc(420);

    width: 100%;
    // overflow: hidden;
    background-color: $light-gray;

    a {
        @include normal-anchor();
    }

    .footer-subline-mobile{
        font-size: 14px;
        svg{
            height: 20px;
        }
    }

    .footer-subline {
        border-top: 1px solid $black;
        .logo-text{
            font-size: 24px !important;
            font-family: 'Satisfy', cursive;
        }
    }

    .footer-content {
        background-color: $white;

        .kuhkopf{
            svg{
                width: 100%;
            }
        }
    }

    .contact {
        .grid-container {
            position: relative;
            // overflow: hidden;
            color: $white;

            .hollow {
                transition-delay: 1s;
                transition: background-image 500ms ease;

                &:hover {
                    transition: background-image 200ms ease;
                    background-image: linear-gradient(95deg, get-color(primary), get-color(secondary));
                    border-color: transparent;
                    color: $white;
                }
            }



            .content {
                position: relative;
                z-index: 20;
            }
        }

        svg.overlay {
            position: absolute;
            right: 0px;
            top: -50px;

            path {
                fill: $dark-gray;
            }
        }
    }

    .footer-menu {
        padding-top: rem-calc(36);
        padding-bottom: rem-calc(36);
        background-color: $white;


        svg {
            width: 100%;
            height: auto;
        }

        .menu {


            li {
                &:last-child {
                    a {
                        // padding-right: 0;
                    }
                }

                a {
                    color: $black;

                    &:hover {
                        color: get-color(primary)
                    }
                }
            }
        }
    }

    @include breakpoint(small only) {
        height: auto;

        .grid-container {
            padding: 0;
        }

        .contact {
            div {

                h2,
                p,
                a {
                    max-width: 83.3333333333%
                }

                background-color: $dark-gray;

                &.small-green {
                    background-color: get-color(primary);
                }

                .content {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        svg.overlay {
            display: none;
        }

        .footer-menu {
            padding-bottom: 0;

            .menu {
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
            }

            .gray {
                background-color: $light-gray;
            }
        }
    }

    .mobile-ilustration{
        svg{
            width: 100%;
        }
    }

    .accordion-content{
        display: none;
    }

    .is-active{
        svg{
            &.arrow {
                transform: rotate(-90deg) !important;
            }
        }
    }
    .accordion-title{

        // color: $black;
        text-transform: none;

        h2{
            margin-top: 0.5rem;
        }

        svg {

            &.arrow {
                transform: rotate(90deg);
            }

            .cls-1,
            .cls-2 {
                fill: none !important;
            }
        }
    }

    @include breakpoint(medium) {
        background-color: $light-gray;

        .contact {
            background-color: $dark-gray;

            .grid-container {
                padding-bottom: rem-calc(40);
                padding-top: 2rem;
            }
        }

        .footer-menu {
            .menu {
                -webkit-box-pack: end;
                -webkit-justify-content: flex-end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                padding-right: 0.5rem;
            }
        }
    }
}
