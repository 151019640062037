$blue: #406caf;
$green: #45a659;
$red: #ba2d2d;
$pink: #e896a8;
$yellow: #edcc3d;

$foundation-palette: (
  primary: $red,
  secondary: $blue,
  success: $green,
  warning: $yellow,
  alert: $red
);


$light-gray: #ededed;
$medium-gray: #f3f3f3;
$dark-gray: #8a8a8a;
$black: #001526;
$white: #fefefe;
