section.calendar {

    .red {
        color: $red;
    }

    .box-image {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }


    .calendar-entry {

        .dropdown {
            display: none;

            &.shown {
                display: flex;
            }

            a {
                color: $black;
                text-transform: none;
            }
        }

        .date {
            h1 {
                font-size: 3.2rem;
            }
        }

        .info-toggle {

            .open-up {
                display: flex;

                &.hidden {
                    display: none;
                }
            }

            .close-down {
                display: none;

                &.shown {
                    display: flex;
                }
            }

            svg {

                &.arrow-up {
                    transform: rotate(-90deg);
                }

                &.arrow-down {
                    transform: rotate(90deg);
                }

                .cls-1,
                .cls-2 {
                    fill: none !important;
                }

                polyline {
                    stroke: $red;
                }

                polyline:hover {
                    stroke: #bf2417;
                }
            }
        }
    }
}