section.lead {
    background: #CB356B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #BD3F32, #CB356B);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #BD3F32, #CB356B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    background-color: $light-gray;

    margin-bottom: 4rem;

    .headline{
        text-transform: uppercase;
    }
    @include breakpoint(small only) {
        .grid-container {
            padding-left: 1rem;
            padding-bottom: 2rem;

        }
    }
    .highlight{
        color: $primary-color;
    }
    @include breakpoint(medium) {
        h1{
            font-family: 'Satisfy', cursive;
            font-size: 80px;
        }
    }
    .sub-title{
        color: white;
    }
}
