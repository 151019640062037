.footer-accordion {
    a {
        @include normal-anchor();
    }


    .accordion-content {
        display: none;
    }

    .is-active {
        svg {
            &.arrow {
                transform: rotate(-90deg) !important;
            }
        }
    }

    a.accordion-title {

        @include normal-anchor();

        &:hover {
            svg {
                polyline {
                    stroke: $anchor-color-hover;
                }
            }
        }

        h2 {
            margin-top: 0.5rem;
        }

        svg {
            &.arrow {
                transform: rotate(90deg);
            }
        }
    }
}