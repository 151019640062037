section.dropdown {
    .accordion {
        #graphic-conatiner {

            path.info-link {
                fill: $black;

                &.is-active {
                    fill: $blue !important;
                }
            }
        }

        .accordion-item {
            border-radius: 8px;

            &.is-active {
                .accordion-title {
                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }

            .accordion-title {
                color: $black;
                text-transform: none;

                h2 {
                    margin-top: 0.5rem;
                }

                @include normal-anchor();

                &:hover {
                    svg {
                        polyline {
                            stroke: $anchor-color-hover;
                        }
                    }
                }

                .open-up {
                    display: flex;

                    &.hidden {
                        display: none;
                    }
                }

                .close-down {
                    display: none;

                    &.shown {
                        display: flex;
                    }
                }

                svg {
                    transition: color 300ms ease;
                    transform: rotate(90deg);

                    .cls-1,
                    .cls-2 {
                        fill: none !important;
                    }
                }
            }

            .accordion-content {
                display: none;
            }

        }
    }
}