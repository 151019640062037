header.header {

    .header-conatiner {

        .img-video-container {
            height: 100vh;
            max-height: 800px;

            position: relative;

            .video-container,
            .header-image {
                overflow-y: hidden;
                height: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }



        .overlay-container {
            position: relative;
            transform: translateY(-50%);

            .overlay {
                position: relative;

                svg {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                .content {
                    position: relative;
                    z-index: 2
                }

                .links {
                    position: absolute;
                    top: 100%;
                }
            }
        }

        .illustration {
            width: 100%;

            transform: translateX(-25%);
            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    @include breakpoint(small only) {
        .header-conatiner {
            .overlay-container {

                .overlay,
                .links {
                    padding-left: 9.0909090909%;
                    padding-right: 9.0909090909%;
                }
            }
        }
    }


    @include breakpoint(medium only) {
        .grid-container {}
    }

    @include breakpoint(large) {
        .grid-container {}
    }
}