body {
    min-height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    // font-size: 1.3em;
    // background-image: linear-gradient(180deg, get-color(primary), get-color(secondary));

    .white {
        background-color: $white;
    }

    .gray {
        background-color: $light-gray;
    }

    main {
        background-color: $white;
        min-height: 100vh;
        flex: 1;
    }

    &.gray {
        background-color: $light-gray;

        main {
            background-color: $light-gray;
        }
    }

    &.black {
        background-color: $black;

        main {
            background-color: $black;
        }
    }

    &.white {
        background-color: $white;

        main {
            background-color: $white;
        }
    }



    .off-canvas-content {
        min-height: 100%;
        max-width: 100vw;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        min-height: 100vh;
        flex-direction: column;

        &.has-transition-overlap {}

    }

    section,
    header {
        position: relative;

        &.gray {
            background-color: $light-gray;

            main {
                background-color: $light-gray;
            }
        }

        &.black {
            background-color: $black;

            main {
                background-color: $black;
            }
        }

        &.white {
            background-color: $white;

            main {
                background-color: $white;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }

    video {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    .video-container {
        cursor: pointer;
    }

    .grid-container.no-padding {
        padding-left: 0;
        padding-right: 0;
    }

    // @include breakpoint(medium) {

    //     main {
    //         margin-top: rem-calc(96);
    //     }

    //     header {
    //         margin-top: rem-calc(-96);
    //     }
    // }
}
