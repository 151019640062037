section.product-list {

    .artist-img{
        height: 400px;
        background-color: $primary-color;
        background-size: cover;
        background-position: center;
    }

    .sheet-img{
        background-color: white;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.07);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.07);
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.07);
    }

    audio{
        width: 100%;
    }

    @include breakpoint(small only) {
        .artist-img{
            height: 300px;
        }
    }
}
