section.box-milestones {

    .milestone-box {

        position: relative;

        @include breakpoint(medium) {
            -webkit-box-shadow: 11px 11px 0px 0px rgba(245,171,149,1);
            -moz-box-shadow: 11px 11px 0px 0px rgba(245,171,149,1);
            box-shadow: 11px 11px 0px 0px rgba(245,171,149,1);
        }

    }

    .illustration {

        // width: 100%;
        position: absolute;


        &.top-left {
            transform: translate(-50%, -50%) rotate(45deg);
            top: 0;
            left: 0;

            svg {
                // height: 10rem;
                width: 16rem;
            }

        }

        &.middle-right {
            transform: translate(50%, -50%) rotate(90deg);
            top: 0;
            right: 0;

            svg {
                // height: 10rem;
                width: 16rem;
            }

        }

    }

}
