@mixin normal-anchor {
    color: inherit;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: normal;

    &:hover {
        color: scale-color($anchor-color, $lightness: -25%);
    }
}