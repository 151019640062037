section.calendar-teaser {
    .slider {
        margin-left: 0 !important;
        @include xy-gutters($gutter-type: padding, $negative: true, $gutter-position: left right);

        a {
            @include normal-anchor();
        }

        .slick-slider{
            padding-bottom: 0px;
        }

        .slick-track {
            display: flex !important;

            .slide {
                height: inherit !important;
                @include xy-gutters($gutter-type: padding, $gutter-position: left right);

                .content-container {
                    height: 100%;

                    .content {
                        // padding: rem-calc(48) rem-calc(32) rem-calc(32) rem-calc(32);
                        @include xy-gutters($gutter-type: padding, $gutter-position: top left right bottom);
                        background-color: $white;
                        // height: 100%;
                    }
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .slick-dotted {
            margin-bottom: 0px;
        }

        .slick-dots {
            display: flex;
            position: relative;
            bottom: 0px;
            justify-content: center;
            // bottom: -50px;
            margin: 0;
            padding-top: 2rem;

            list-style-type: none;

            li {
                margin: 0 0.25rem;
            }

            button {
                display: block;
                width: 0.9rem;
                height: 0.9rem;
                padding: 0;

                border: none;
                border-radius: 100%;
                background-color: $white;

                text-indent: -9999px;
            }

            li.slick-active button {
                background-color: $red;
            }
        }
    }
}
