.button {
    cursor: pointer;
    background-repeat: no-repeat;
    padding: 0.85rem 1rem;

    &.hollow {
        color: $white;
        background: transparent;
        border-color: $white;
        border-width: 1px;

        &:hover {
            background-color: $white;
            color: $black !important;
            border-color: $white;
        }

        &.black {
            background-color: transparent;
            color: $black;
            border: solid 1px $black;

            &:hover {
                background-image: linear-gradient(95deg, rgba(get-color(primary), 1), rgba(get-color(secondary), 1));
                color: $black;
                border-color: transparent;
            }
        }
    }

    &.gradient {
        background: linear-gradient(45deg, get-color(primary), get-color(secondary), get-color(primary), get-color(secondary), get-color(primary));
        background-size: 400% 400%;
        animation: gradient-scroll 7s cubic-bezier(0.645, 0.045, 0.355, 1.000) reverse infinite;
        will-change: background-position;
        transform: translateZ(0);
        color: $white;
        font-weight: bold;

        @include breakpoint(medium) {
            font-size: rem-calc(22);
        }

        &:hover {
            color: $black;
            animation-duration: 30s
        }
    }

    &.black {
        background-color: $black;
        color: $white;
        border: none;
        border: 1px solid $black;

        &:hover {
            animation-duration: 30s;
            background-color: $white;
            color: $black;
            border: 1px solid $black;
        }
    }

    &.white {
        background-color: $white;
        color: $black;
        border: none;

        &:hover {
            background-color: $black;
            animation: background-color;
            color: get-color(primary);
        }
    }

    &.large {
        font-weight: bold;
    }

    @include breakpoint(medium) {
        font-size: rem-calc(18);
    }
}
