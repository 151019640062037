.main-nav {
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: $black;

    .grid-container {
        padding: 0;

        .top-bar {
            // @include top-bar-unstack;

            a {
                @include normal-anchor();
            }

            .top-bar-center,
            .top-bar-right {
                .logo-text{
                    color: white;
                    font-size: 24px !important;
                    font-family: 'Satisfy', cursive;
                }
                .menu {
                    li {
                        a {
                            transition: color 300ms ease;
                            text-transform: none;
                            font-size: rem-calc(18);
                            line-height: 1.78;
                            font-weight: normal;
                            position: relative;
                            min-width: 36px;

                            svg {
                                position: absolute;
                                transition: fill 500ms ease;
                                margin: auto;
                                width: auto;
                                height: 28px;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }

            .top-bar-right {
                -webkit-box-flex: 0;
                flex: 0 0 auto;
            }

            .top-bar-left {
                -webkit-box-flex: 0;
                flex: 0 0 auto;

                a {
                    svg {
                        width: auto;
                        transition: fill 500ms ease;
                        height: rem-calc(65);
                    }
                }
            }
            .top-bar-center{
            }

            .menu {
                a {
                    transition-delay: 1s;
                    transition: fill 500ms ease;
                    color: $white;
                    padding: .7rem 0.5rem;

                    &:hover {
                        color: $anchor-color-hover
                    }

                    &.is-active {
                        font-weight: bold !important;
                    }
                }
            }

            .top-bar-left {
                .dropdown.menu {
                    li.is-dropdown-submenu-parent {
                        a {
                            padding: 1rem 1rem;
                        }

                        a::after {
                            transition: border-color 500ms ease;
                            border-color: $white transparent transparent;
                        }

                        a:hover {
                            &::after {
                                transition: border-color 500ms ease;
                            }
                        }
                    }

                    .is-dropdown-submenu {
                        background-color: $white;
                        border: none;

                        li {
                            a {
                                color: $black;

                                &:hover {
                                    transition: border-color 500ms ease;
                                    background-color: get-color(primary);
                                }

                            }
                        }
                    }
                }

                .langswitcher {
                    text-transform: uppercase;
                }
            }

            .top-bar-right {

                .contact {
                    border: solid 0.5px $white;
                }
            }
        }
    }
}
