section.sliding-boxes {

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }

    .slide {

        svg {
            // height: 90px;
            width:100%;
            height: auto;
        }

        .slide-img {
            height: 280px;
            background-size: cover;
            background-position: center;
        }

    }

    @include breakpoint(small only) {
        background-color: $light-gray !important;
    }

    .slick-dotted{
        margin-bottom: 0px;
    }

    .slick-slider{
        padding-bottom: 0px;
    }

    .slick-dots {
        display: flex;
        position: relative;
        bottom: 0px;
        justify-content: center;
        // bottom: -50px;
        margin: 0;
        padding-top: 2rem;

        list-style-type: none;

        li {
            margin: 0 0.25rem;
        }

        button {
            display: block;
            width: 0.9rem;
            height: 0.9rem;
            padding: 0;

            border: none;
            border-radius: 100%;
            background-color: $white;

            text-indent: -9999px;
        }

        li.slick-active button {
            background-color: $red;
        }
    }
}
