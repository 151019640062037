@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 53.125em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=53.125em&large=96.875em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 19px; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1;
  color: #2e2e2e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 84.21053rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.52632rem;
    margin-left: -0.52632rem; }
    @media print, screen and (min-width: 53.125em) {
      .row .row {
        margin-right: -0.78947rem;
        margin-left: -0.78947rem; } }
    @media print, screen and (min-width: 96.875em) {
      .row .row {
        margin-right: -0.78947rem;
        margin-left: -0.78947rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.52632rem;
    padding-left: 0.52632rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.78947rem;
    padding-left: 0.78947rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.52632rem;
  padding-left: 0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .column, .columns {
      padding-right: 0.78947rem;
      padding-left: 0.78947rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.52632rem;
  padding-left: 0.52632rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 53.125em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.78947rem;
    padding-left: 0.78947rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 96.875em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.78947rem;
    padding-left: 0.78947rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.05263rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .column-block {
      margin-bottom: 1.57895rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.row {
  max-width: 84.21053rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.52632rem;
    margin-left: -0.52632rem; }
    @media print, screen and (min-width: 53.125em) {
      .row .row {
        margin-right: -0.78947rem;
        margin-left: -0.78947rem; } }
    @media print, screen and (min-width: 96.875em) {
      .row .row {
        margin-right: -0.78947rem;
        margin-left: -0.78947rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.52632rem;
  padding-left: 0.52632rem;
  min-width: 0; }
  @media print, screen and (min-width: 53.125em) {
    .column, .columns {
      padding-right: 0.78947rem;
      padding-left: 0.78947rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.52632rem;
  padding-left: 0.52632rem; }

@media print, screen and (min-width: 53.125em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 53.125em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 53.125em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.78947rem;
    padding-left: 0.78947rem; } }

@media print, screen and (min-width: 96.875em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 96.875em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 96.875em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.78947rem;
    padding-left: 0.78947rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.05263rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .column-block {
      margin-bottom: 1.57895rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.grid-container {
  padding-right: 0.52632rem;
  padding-left: 0.52632rem;
  max-width: 84.21053rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 53.125em) {
    .grid-container {
      padding-right: 0.78947rem;
      padding-left: 0.78947rem; } }
  .grid-container.fluid {
    padding-right: 0.52632rem;
    padding-left: 0.52632rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 53.125em) {
      .grid-container.fluid {
        padding-right: 0.78947rem;
        padding-left: 0.78947rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 53.125em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 96.875em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 53.125em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.52632rem;
  margin-right: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-x {
      margin-left: -0.78947rem;
      margin-right: -0.78947rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.05263rem);
    margin-left: 0.52632rem;
    margin-right: 0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.57895rem);
      margin-left: 0.78947rem;
      margin-right: 0.78947rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.05263rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.05263rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.05263rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.05263rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.05263rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.05263rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.05263rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.05263rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.05263rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.05263rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.05263rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.05263rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.57895rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.57895rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.57895rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.57895rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.57895rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.57895rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.57895rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.57895rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.57895rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.57895rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.57895rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.57895rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.57895rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.57895rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.57895rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.57895rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.57895rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.57895rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.57895rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.57895rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.57895rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.57895rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.57895rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.57895rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.57895rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.57895rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.57895rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.57895rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.57895rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.57895rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.57895rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.57895rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.57895rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.57895rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.57895rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.57895rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.52632rem;
  margin-left: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.78947rem;
      margin-left: -0.78947rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.52632rem;
  margin-left: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.78947rem;
      margin-left: -0.78947rem; } }

.grid-padding-x > .cell {
  padding-right: 0.52632rem;
  padding-left: 0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-x > .cell {
      padding-right: 0.78947rem;
      padding-left: 0.78947rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 53.125em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 96.875em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.05263rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.05263rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.05263rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.05263rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.05263rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.05263rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.05263rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.05263rem); }

@media print, screen and (min-width: 53.125em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.05263rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.05263rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.05263rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.05263rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.05263rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.05263rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.05263rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.05263rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.57895rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.57895rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.57895rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.57895rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.57895rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.57895rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.57895rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.57895rem); } }

@media print, screen and (min-width: 96.875em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.57895rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.57895rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.57895rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.57895rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.57895rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.57895rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.57895rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.57895rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 96.875em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 53.125em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 53.125em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.125em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.125em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.52632rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.52632rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.52632rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.52632rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.52632rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.52632rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.52632rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.52632rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.52632rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.52632rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.52632rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.52632rem); }

@media print, screen and (min-width: 53.125em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.78947rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.78947rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.78947rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.78947rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.78947rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.78947rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.78947rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.78947rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.78947rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.78947rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.78947rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.78947rem); } }

@media print, screen and (min-width: 96.875em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.78947rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.78947rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.78947rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.78947rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.78947rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.78947rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.78947rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.78947rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.78947rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.78947rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.78947rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.78947rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 53.125em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 96.875em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 96.875em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.52632rem;
  margin-bottom: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.78947rem;
      margin-bottom: -0.78947rem; } }

.grid-padding-y > .cell {
  padding-top: 0.52632rem;
  padding-bottom: 0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-padding-y > .cell {
      padding-top: 0.78947rem;
      padding-bottom: 0.78947rem; } }

.grid-margin-y {
  margin-top: -0.52632rem;
  margin-bottom: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y {
      margin-top: -0.78947rem;
      margin-bottom: -0.78947rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.05263rem);
    margin-top: 0.52632rem;
    margin-bottom: 0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.57895rem);
      margin-top: 0.78947rem;
      margin-bottom: 0.78947rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.05263rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.05263rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.05263rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.05263rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.05263rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.05263rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.05263rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.05263rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.05263rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.05263rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.05263rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.05263rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.57895rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.57895rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.57895rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.57895rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.57895rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.57895rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.57895rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.57895rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.57895rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.57895rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.57895rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.57895rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.57895rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.57895rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.57895rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.57895rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.57895rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.57895rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.57895rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.57895rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.57895rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.57895rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.57895rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.57895rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.57895rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.57895rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.57895rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.57895rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.57895rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.57895rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.57895rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.57895rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.57895rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.57895rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.57895rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.57895rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 53.125em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 96.875em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 53.125em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 96.875em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 53.125em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.52632rem;
  margin-bottom: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y {
      margin-top: -0.78947rem;
      margin-bottom: -0.78947rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.05263rem);
    margin-top: 0.52632rem;
    margin-bottom: 0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.57895rem);
      margin-top: 0.78947rem;
      margin-bottom: 0.78947rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.05263rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.05263rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.05263rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.05263rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.05263rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.05263rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.05263rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.05263rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.05263rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.05263rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.05263rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.05263rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.57895rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.57895rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.57895rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.57895rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.57895rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.57895rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.57895rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.57895rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.57895rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.57895rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.57895rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.57895rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.57895rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.57895rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.57895rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.57895rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.57895rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.57895rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.57895rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.57895rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.57895rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.57895rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.57895rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.57895rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.57895rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.57895rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.57895rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.57895rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.57895rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.57895rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.57895rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.57895rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.57895rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.57895rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.57895rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.57895rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.05263rem); }
  @media print, screen and (min-width: 53.125em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.57895rem); } }
  @media print, screen and (min-width: 96.875em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.57895rem); } }

@media print, screen and (min-width: 53.125em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.57895rem); } }

@media print, screen and (min-width: 96.875em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.57895rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.5;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 75%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "FFHertzWebProBold", "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #2e2e2e; }

h1, .h1 {
  font-size: 1.68421rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.26316rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.10526rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.10526rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.10526rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1.10526rem;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 53.125em) {
  h1, .h1 {
    font-size: 2.10526rem; }
  h2, .h2 {
    font-size: 1.68421rem; }
  h3, .h3 {
    font-size: 1.26316rem; }
  h4, .h4 {
    font-size: 1.10526rem; }
  h5, .h5 {
    font-size: 1.10526rem; }
  h6, .h6 {
    font-size: 1.10526rem; } }

@media print, screen and (min-width: 96.875em) {
  h1, .h1 {
    font-size: 2.10526rem; }
  h2, .h2 {
    font-size: 1.68421rem; }
  h3, .h3 {
    font-size: 1.26316rem; }
  h4, .h4 {
    font-size: 1.10526rem; }
  h5, .h5 {
    font-size: 1.10526rem; }
  h6, .h6 {
    font-size: 1.10526rem; } }

a {
  line-height: inherit;
  color: #ba2d2d;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #8c2222; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 84.21053rem;
  height: 0;
  margin: 1.05263rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #f3f3f3;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.5; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0 0 0 1.31579rem;
  border-left: 4px solid #ba2d2d; }
  blockquote, blockquote p {
    line-height: 1.5;
    color: #2e2e2e; }

cite {
  display: block;
  font-size: 0.68421rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #001526;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.10526rem 0.26316rem 0.05263rem;
  border: 1px solid #f3f3f3;
  background-color: #ededed;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #001526; }

kbd {
  margin: 0;
  padding: 0.10526rem 0.21053rem 0;
  background-color: #ededed;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #001526; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 38px;
  line-height: 1; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 53.125em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 96.875em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 2em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #ba2d2d;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #9e2626;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #ba2d2d;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #952424;
      color: #fefefe; }
  .button.secondary {
    background-color: #406caf;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #33568c;
      color: #fefefe; }
  .button.success {
    background-color: #45a659;
    color: #001526; }
    .button.success:hover, .button.success:focus {
      background-color: #378547;
      color: #001526; }
  .button.warning {
    background-color: #edcc3d;
    color: #001526; }
    .button.warning:hover, .button.warning:focus {
      background-color: #dab514;
      color: #001526; }
  .button.alert {
    background-color: #ba2d2d;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #952424;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #ba2d2d;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #ba2d2d;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #406caf;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #45a659;
        color: #001526; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #edcc3d;
        color: #001526; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #ba2d2d;
        color: #fefefe; }
  .button.hollow {
    border: 0.5px solid #ba2d2d;
    color: #ba2d2d; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #5d1717;
      color: #5d1717; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 0.5px solid #ba2d2d;
        color: #ba2d2d; }
    .button.hollow.primary {
      border: 0.5px solid #ba2d2d;
      color: #ba2d2d; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #5d1717;
        color: #5d1717; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 0.5px solid #ba2d2d;
          color: #ba2d2d; }
    .button.hollow.secondary {
      border: 0.5px solid #406caf;
      color: #406caf; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #203658;
        color: #203658; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 0.5px solid #406caf;
          color: #406caf; }
    .button.hollow.success {
      border: 0.5px solid #45a659;
      color: #45a659; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #23532d;
        color: #23532d; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 0.5px solid #45a659;
          color: #45a659; }
    .button.hollow.warning {
      border: 0.5px solid #edcc3d;
      color: #edcc3d; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #88710d;
        color: #88710d; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 0.5px solid #edcc3d;
          color: #edcc3d; }
    .button.hollow.alert {
      border: 0.5px solid #ba2d2d;
      color: #ba2d2d; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #5d1717;
        color: #5d1717; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 0.5px solid #ba2d2d;
          color: #ba2d2d; }
  .button.clear {
    border: 0.5px solid #ba2d2d;
    color: #ba2d2d; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #5d1717;
      color: #5d1717; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 0.5px solid #ba2d2d;
        color: #ba2d2d; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 0.5px solid #ba2d2d;
      color: #ba2d2d; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #5d1717;
        color: #5d1717; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 0.5px solid #ba2d2d;
          color: #ba2d2d; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 0.5px solid #406caf;
      color: #406caf; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #203658;
        color: #203658; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 0.5px solid #406caf;
          color: #406caf; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 0.5px solid #45a659;
      color: #45a659; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #23532d;
        color: #23532d; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 0.5px solid #45a659;
          color: #45a659; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 0.5px solid #edcc3d;
      color: #edcc3d; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #88710d;
        color: #88710d; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 0.5px solid #edcc3d;
          color: #edcc3d; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 0.5px solid #ba2d2d;
      color: #ba2d2d; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #5d1717;
        color: #5d1717; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 0.5px solid #ba2d2d;
          color: #ba2d2d; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 2em; }
  .button.dropdown.hollow::after {
    border-top-color: #ba2d2d; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #ba2d2d; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #406caf; }
  .button.dropdown.hollow.success::after {
    border-top-color: #45a659; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #edcc3d; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #ba2d2d; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 1.63158rem;
  margin: 0 0 0.84211rem;
  padding: 0.42105rem;
  border: 1px solid #f3f3f3;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(0, 21, 38, 0.1);
  font-family: inherit;
  font-size: 0.84211rem;
  font-weight: normal;
  line-height: 1;
  color: #001526;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #f3f3f3;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #f3f3f3; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #ededed;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 0.84211rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.42105rem;
  margin-right: 0.84211rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.42105rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.73684rem;
  font-weight: normal;
  line-height: 1.8;
  color: #001526; }
  label.middle {
    margin: 0 0 0.84211rem;
    padding: 0.47368rem 0; }

.help-text {
  margin-top: -0.42105rem;
  font-size: 0.68421rem;
  font-style: italic;
  color: #001526; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 0.84211rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #f3f3f3;
  background: #ededed;
  color: #001526;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.84211rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.42105rem; }

.fieldset {
  margin: 0.94737rem 0;
  padding: 1.05263rem;
  border: 1px solid #f3f3f3; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.15789rem;
    padding: 0 0.15789rem; }

select {
  height: 1.63158rem;
  margin: 0 0 0.84211rem;
  padding: 0.42105rem;
  appearance: none;
  border: 1px solid #f3f3f3;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 0.84211rem;
  font-weight: normal;
  line-height: 1;
  color: #001526;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -0.84211rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.26316rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #f3f3f3;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #ededed;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #ba2d2d;
  background-color: #f7e9e9; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #ba2d2d; }

.is-invalid-label {
  color: #ba2d2d; }

.form-error {
  display: none;
  margin-top: -0.42105rem;
  margin-bottom: 0.84211rem;
  font-size: 0.63158rem;
  font-weight: bold;
  color: #ba2d2d; }
  .form-error.is-visible {
    display: block; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #ba2d2d;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #952424;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #406caf;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #33568c;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #45a659;
    color: #001526; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #378547;
      color: #001526; }
  .button-group.warning .button {
    background-color: #edcc3d;
    color: #001526; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #dab514;
      color: #001526; }
  .button-group.alert .button {
    background-color: #ba2d2d;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #952424;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 96.875em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 53.0625em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0.5rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 53.125em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 96.875em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #ba2d2d;
    color: #fefefe; }
  .menu .active > a {
    background: #ba2d2d;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.5rem 0.5rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #ba2d2d transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.5rem 0.5rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #ba2d2d; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #ba2d2d transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #ba2d2d; }

@media print, screen and (min-width: 53.125em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ba2d2d transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #ba2d2d transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #ba2d2d; } }

@media print, screen and (min-width: 96.875em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ba2d2d transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #ba2d2d transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #ba2d2d; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #f3f3f3;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.5rem 0.5rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #ba2d2d transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #ba2d2d; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(138, 138, 138, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1000;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #ededed; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 1000; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 1001; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 0px rgba(0, 21, 38, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1000;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #ededed; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 1000; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 1001; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 0px rgba(0, 21, 38, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  transform: translateX(-100%); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-100%); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(100%); }
  .position-left.is-transition-push {
    box-shadow: inset 0px 0 0px 0px rgba(0, 21, 38, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  transform: translateX(100%); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(100%); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-100%); }
  .position-right.is-transition-push {
    box-shadow: inset 0px 0 0px 0px rgba(0, 21, 38, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 0px 0px 0px rgba(0, 21, 38, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 0px 0px 0px rgba(0, 21, 38, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 53.125em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 100%; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 100%; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 100%; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 100%; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media print, screen and (min-width: 96.875em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 100%; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 100%; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 100%; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 100%; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 100%; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 100%; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1000;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 100%; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 100%; } }

@media print, screen and (min-width: 53.125em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 96.875em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #ededed;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #f3f3f3;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #ba2d2d;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #9e2626; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 21, 38, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #f3f3f3;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 53.125em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 53.125em) {
    .reveal {
      width: 600px;
      max-width: 84.21053rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 53.125em) {
    .reveal.tiny {
      width: 30%;
      max-width: 84.21053rem; } }
  @media print, screen and (min-width: 53.125em) {
    .reveal.small {
      width: 50%;
      max-width: 84.21053rem; } }
  @media print, screen and (min-width: 53.125em) {
    .reveal.large {
      width: 90%;
      max-width: 84.21053rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 53.0625em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: tranparent; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 53.125em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 96.8125em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 53.0625em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 53.125em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 53.125em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 53.0625em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 53.125em) and (max-width: 96.8125em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 53.0625em), screen and (min-width: 96.875em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 96.875em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 96.8125em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 96.875em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 96.8125em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 53.125em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 96.875em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 53.125em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 96.875em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-ccw-1turn; }

@keyframes spin-ccw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate(0, 0); }

[data-aos='fade-up'] {
  transform: translate(0, 100px); }

[data-aos='fade-down'] {
  transform: translate(0, -100px); }

[data-aos='fade-right'] {
  transform: translate(-100px, 0); }

[data-aos='fade-left'] {
  transform: translate(100px, 0); }

[data-aos='fade-up-right'] {
  transform: translate(-100px, 100px); }

[data-aos='fade-up-left'] {
  transform: translate(100px, 100px); }

[data-aos='fade-down-right'] {
  transform: translate(-100px, -100px); }

[data-aos='fade-down-left'] {
  transform: translate(100px, -100px); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate(0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate(0, 100px) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate(0, -100px) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate(-100px, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate(100px, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate(0, 100px) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate(0, -100px) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate(-100px, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate(100px, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate(0, 0); }

[data-aos='slide-up'] {
  transform: translateY(100%); }

[data-aos='slide-down'] {
  transform: translateY(-100%); }

[data-aos='slide-right'] {
  transform: translateX(-100%); }

[data-aos='slide-left'] {
  transform: translateX(100%); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

.slick-dotted.slick-slider {
  padding-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.index-menu {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .index-menu .menu a {
    text-transform: uppercase;
    color: #2e2e2e;
    transition: color 0.2s ease-in; }
    .index-menu .menu a:hover {
      color: #ba2d2d; }

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent; }

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast; }

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important; }

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y; }

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom; }

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none; }

.leaflet-container {
  -webkit-tap-highlight-color: transparent; }

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4); }

.leaflet-tile {
  filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-pane {
  z-index: 400; }

.leaflet-tile-pane {
  z-index: 200; }

.leaflet-overlay-pane {
  z-index: 400; }

.leaflet-shadow-pane {
  z-index: 500; }

.leaflet-marker-pane {
  z-index: 600; }

.leaflet-tooltip-pane {
  z-index: 650; }

.leaflet-popup-pane {
  z-index: 700; }

.leaflet-map-pane canvas {
  z-index: 100; }

.leaflet-map-pane svg {
  z-index: 200; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity; }

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-interactive {
  cursor: pointer; }

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab; }

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none; }

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0; }

.leaflet-container a {
  color: #0078A8; }

.leaflet-container a.leaflet-active {
  outline: 2px solid orange; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif; }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px; }

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black; }

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block; }

.leaflet-bar a:hover {
  background-color: #f4f4f4; }

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb; }

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px; }

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px; }

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff; }

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png); }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover {
  text-decoration: underline; }

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5); }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px; }

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4; }

.leaflet-popup-content p {
  margin: 18px 0; }

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none; }

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999; }

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px; }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666; }

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); }

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto; }

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px; }

.leaflet-tooltip-top {
  margin-top: -6px; }

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px; }

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff; }

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff; }

.leaflet-tooltip-left {
  margin-left: -6px; }

.leaflet-tooltip-right {
  margin-left: 6px; }

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px; }

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff; }

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff; }

.small-margin-top-0 {
  margin-top: 0; }

.small-margin-top-1 {
  margin-top: 0.42105rem; }

.small-margin-top-2 {
  margin-top: 0.84211rem; }

.small-margin-top-3 {
  margin-top: 1.26316rem; }

.small-margin-top-4 {
  margin-top: 1.68421rem; }

.small-margin-top-5 {
  margin-top: 2.10526rem; }

.small-margin-top-6 {
  margin-top: 2.52632rem; }

.small-margin-top-7 {
  margin-top: 2.94737rem; }

.small-margin-top-8 {
  margin-top: 3.36842rem; }

.small-margin-top-9 {
  margin-top: 3.78947rem; }

.small-margin-top-10 {
  margin-top: 4.21053rem; }

.small-margin-top-11 {
  margin-top: 4.63158rem; }

.small-margin-top-12 {
  margin-top: 5.05263rem; }

.small-margin-top-13 {
  margin-top: 5.47368rem; }

.small-margin-top-14 {
  margin-top: 5.89474rem; }

.small-margin-top-15 {
  margin-top: 6.31579rem; }

.small-margin-top-16 {
  margin-top: 6.73684rem; }

.small-margin-top-17 {
  margin-top: 7.15789rem; }

.small-margin-top-18 {
  margin-top: 7.57895rem; }

.small-margin-top-19 {
  margin-top: 8rem; }

.small-margin-top-20 {
  margin-top: 8.42105rem; }

.small-margin-top-21 {
  margin-top: 8.84211rem; }

.small-margin-top-22 {
  margin-top: 9.26316rem; }

.small-margin-top-23 {
  margin-top: 9.68421rem; }

.small-margin-top-24 {
  margin-top: 10.10526rem; }

.small-margin-top-25 {
  margin-top: 10.52632rem; }

.small-margin-top-26 {
  margin-top: 10.94737rem; }

.small-margin-top-27 {
  margin-top: 11.36842rem; }

.small-margin-top-28 {
  margin-top: 11.78947rem; }

.small-margin-top-29 {
  margin-top: 12.21053rem; }

.small-margin-top-30 {
  margin-top: 12.63158rem; }

.small-margin-top-31 {
  margin-top: 13.05263rem; }

.small-margin-top-32 {
  margin-top: 13.47368rem; }

.small-margin-top-33 {
  margin-top: 13.89474rem; }

.small-margin-top-34 {
  margin-top: 14.31579rem; }

.small-margin-top-35 {
  margin-top: 14.73684rem; }

.small-margin-top-36 {
  margin-top: 15.15789rem; }

.small-margin-top-37 {
  margin-top: 15.57895rem; }

.small-margin-top-38 {
  margin-top: 16rem; }

.small-margin-top-39 {
  margin-top: 16.42105rem; }

.small-margin-top-40 {
  margin-top: 16.84211rem; }

.small-margin-top-41 {
  margin-top: 17.26316rem; }

.small-margin-top-42 {
  margin-top: 17.68421rem; }

.small-margin-top-43 {
  margin-top: 18.10526rem; }

.small-margin-top-44 {
  margin-top: 18.52632rem; }

.small-margin-top-45 {
  margin-top: 18.94737rem; }

.small-margin-top-46 {
  margin-top: 19.36842rem; }

.small-margin-top-47 {
  margin-top: 19.78947rem; }

.small-margin-top-48 {
  margin-top: 20.21053rem; }

.small-margin-bottom-0 {
  margin-bottom: 0; }

.small-margin-bottom-1 {
  margin-bottom: 0.42105rem; }

.small-margin-bottom-2 {
  margin-bottom: 0.84211rem; }

.small-margin-bottom-3 {
  margin-bottom: 1.26316rem; }

.small-margin-bottom-4 {
  margin-bottom: 1.68421rem; }

.small-margin-bottom-5 {
  margin-bottom: 2.10526rem; }

.small-margin-bottom-6 {
  margin-bottom: 2.52632rem; }

.small-margin-bottom-7 {
  margin-bottom: 2.94737rem; }

.small-margin-bottom-8 {
  margin-bottom: 3.36842rem; }

.small-margin-bottom-9 {
  margin-bottom: 3.78947rem; }

.small-margin-bottom-10 {
  margin-bottom: 4.21053rem; }

.small-margin-bottom-11 {
  margin-bottom: 4.63158rem; }

.small-margin-bottom-12 {
  margin-bottom: 5.05263rem; }

.small-margin-bottom-13 {
  margin-bottom: 5.47368rem; }

.small-margin-bottom-14 {
  margin-bottom: 5.89474rem; }

.small-margin-bottom-15 {
  margin-bottom: 6.31579rem; }

.small-margin-bottom-16 {
  margin-bottom: 6.73684rem; }

.small-margin-bottom-17 {
  margin-bottom: 7.15789rem; }

.small-margin-bottom-18 {
  margin-bottom: 7.57895rem; }

.small-margin-bottom-19 {
  margin-bottom: 8rem; }

.small-margin-bottom-20 {
  margin-bottom: 8.42105rem; }

.small-margin-bottom-21 {
  margin-bottom: 8.84211rem; }

.small-margin-bottom-22 {
  margin-bottom: 9.26316rem; }

.small-margin-bottom-23 {
  margin-bottom: 9.68421rem; }

.small-margin-bottom-24 {
  margin-bottom: 10.10526rem; }

.small-margin-bottom-25 {
  margin-bottom: 10.52632rem; }

.small-margin-bottom-26 {
  margin-bottom: 10.94737rem; }

.small-margin-bottom-27 {
  margin-bottom: 11.36842rem; }

.small-margin-bottom-28 {
  margin-bottom: 11.78947rem; }

.small-margin-bottom-29 {
  margin-bottom: 12.21053rem; }

.small-margin-bottom-30 {
  margin-bottom: 12.63158rem; }

.small-margin-bottom-31 {
  margin-bottom: 13.05263rem; }

.small-margin-bottom-32 {
  margin-bottom: 13.47368rem; }

.small-margin-bottom-33 {
  margin-bottom: 13.89474rem; }

.small-margin-bottom-34 {
  margin-bottom: 14.31579rem; }

.small-margin-bottom-35 {
  margin-bottom: 14.73684rem; }

.small-margin-bottom-36 {
  margin-bottom: 15.15789rem; }

.small-margin-bottom-37 {
  margin-bottom: 15.57895rem; }

.small-margin-bottom-38 {
  margin-bottom: 16rem; }

.small-margin-bottom-39 {
  margin-bottom: 16.42105rem; }

.small-margin-bottom-40 {
  margin-bottom: 16.84211rem; }

.small-margin-bottom-41 {
  margin-bottom: 17.26316rem; }

.small-margin-bottom-42 {
  margin-bottom: 17.68421rem; }

.small-margin-bottom-43 {
  margin-bottom: 18.10526rem; }

.small-margin-bottom-44 {
  margin-bottom: 18.52632rem; }

.small-margin-bottom-45 {
  margin-bottom: 18.94737rem; }

.small-margin-bottom-46 {
  margin-bottom: 19.36842rem; }

.small-margin-bottom-47 {
  margin-bottom: 19.78947rem; }

.small-margin-bottom-48 {
  margin-bottom: 20.21053rem; }

.small-margin-right-0 {
  margin-right: 0; }

.small-margin-right-1 {
  margin-right: 0.42105rem; }

.small-margin-right-2 {
  margin-right: 0.84211rem; }

.small-margin-right-3 {
  margin-right: 1.26316rem; }

.small-margin-right-4 {
  margin-right: 1.68421rem; }

.small-margin-right-5 {
  margin-right: 2.10526rem; }

.small-margin-right-6 {
  margin-right: 2.52632rem; }

.small-margin-right-7 {
  margin-right: 2.94737rem; }

.small-margin-right-8 {
  margin-right: 3.36842rem; }

.small-margin-right-9 {
  margin-right: 3.78947rem; }

.small-margin-right-10 {
  margin-right: 4.21053rem; }

.small-margin-right-11 {
  margin-right: 4.63158rem; }

.small-margin-right-12 {
  margin-right: 5.05263rem; }

.small-margin-right-13 {
  margin-right: 5.47368rem; }

.small-margin-right-14 {
  margin-right: 5.89474rem; }

.small-margin-right-15 {
  margin-right: 6.31579rem; }

.small-margin-right-16 {
  margin-right: 6.73684rem; }

.small-margin-right-17 {
  margin-right: 7.15789rem; }

.small-margin-right-18 {
  margin-right: 7.57895rem; }

.small-margin-right-19 {
  margin-right: 8rem; }

.small-margin-right-20 {
  margin-right: 8.42105rem; }

.small-margin-right-21 {
  margin-right: 8.84211rem; }

.small-margin-right-22 {
  margin-right: 9.26316rem; }

.small-margin-right-23 {
  margin-right: 9.68421rem; }

.small-margin-right-24 {
  margin-right: 10.10526rem; }

.small-margin-right-25 {
  margin-right: 10.52632rem; }

.small-margin-right-26 {
  margin-right: 10.94737rem; }

.small-margin-right-27 {
  margin-right: 11.36842rem; }

.small-margin-right-28 {
  margin-right: 11.78947rem; }

.small-margin-right-29 {
  margin-right: 12.21053rem; }

.small-margin-right-30 {
  margin-right: 12.63158rem; }

.small-margin-right-31 {
  margin-right: 13.05263rem; }

.small-margin-right-32 {
  margin-right: 13.47368rem; }

.small-margin-right-33 {
  margin-right: 13.89474rem; }

.small-margin-right-34 {
  margin-right: 14.31579rem; }

.small-margin-right-35 {
  margin-right: 14.73684rem; }

.small-margin-right-36 {
  margin-right: 15.15789rem; }

.small-margin-right-37 {
  margin-right: 15.57895rem; }

.small-margin-right-38 {
  margin-right: 16rem; }

.small-margin-right-39 {
  margin-right: 16.42105rem; }

.small-margin-right-40 {
  margin-right: 16.84211rem; }

.small-margin-right-41 {
  margin-right: 17.26316rem; }

.small-margin-right-42 {
  margin-right: 17.68421rem; }

.small-margin-right-43 {
  margin-right: 18.10526rem; }

.small-margin-right-44 {
  margin-right: 18.52632rem; }

.small-margin-right-45 {
  margin-right: 18.94737rem; }

.small-margin-right-46 {
  margin-right: 19.36842rem; }

.small-margin-right-47 {
  margin-right: 19.78947rem; }

.small-margin-right-48 {
  margin-right: 20.21053rem; }

.small-margin-left-0 {
  margin-left: 0; }

.small-margin-left-1 {
  margin-left: 0.42105rem; }

.small-margin-left-2 {
  margin-left: 0.84211rem; }

.small-margin-left-3 {
  margin-left: 1.26316rem; }

.small-margin-left-4 {
  margin-left: 1.68421rem; }

.small-margin-left-5 {
  margin-left: 2.10526rem; }

.small-margin-left-6 {
  margin-left: 2.52632rem; }

.small-margin-left-7 {
  margin-left: 2.94737rem; }

.small-margin-left-8 {
  margin-left: 3.36842rem; }

.small-margin-left-9 {
  margin-left: 3.78947rem; }

.small-margin-left-10 {
  margin-left: 4.21053rem; }

.small-margin-left-11 {
  margin-left: 4.63158rem; }

.small-margin-left-12 {
  margin-left: 5.05263rem; }

.small-margin-left-13 {
  margin-left: 5.47368rem; }

.small-margin-left-14 {
  margin-left: 5.89474rem; }

.small-margin-left-15 {
  margin-left: 6.31579rem; }

.small-margin-left-16 {
  margin-left: 6.73684rem; }

.small-margin-left-17 {
  margin-left: 7.15789rem; }

.small-margin-left-18 {
  margin-left: 7.57895rem; }

.small-margin-left-19 {
  margin-left: 8rem; }

.small-margin-left-20 {
  margin-left: 8.42105rem; }

.small-margin-left-21 {
  margin-left: 8.84211rem; }

.small-margin-left-22 {
  margin-left: 9.26316rem; }

.small-margin-left-23 {
  margin-left: 9.68421rem; }

.small-margin-left-24 {
  margin-left: 10.10526rem; }

.small-margin-left-25 {
  margin-left: 10.52632rem; }

.small-margin-left-26 {
  margin-left: 10.94737rem; }

.small-margin-left-27 {
  margin-left: 11.36842rem; }

.small-margin-left-28 {
  margin-left: 11.78947rem; }

.small-margin-left-29 {
  margin-left: 12.21053rem; }

.small-margin-left-30 {
  margin-left: 12.63158rem; }

.small-margin-left-31 {
  margin-left: 13.05263rem; }

.small-margin-left-32 {
  margin-left: 13.47368rem; }

.small-margin-left-33 {
  margin-left: 13.89474rem; }

.small-margin-left-34 {
  margin-left: 14.31579rem; }

.small-margin-left-35 {
  margin-left: 14.73684rem; }

.small-margin-left-36 {
  margin-left: 15.15789rem; }

.small-margin-left-37 {
  margin-left: 15.57895rem; }

.small-margin-left-38 {
  margin-left: 16rem; }

.small-margin-left-39 {
  margin-left: 16.42105rem; }

.small-margin-left-40 {
  margin-left: 16.84211rem; }

.small-margin-left-41 {
  margin-left: 17.26316rem; }

.small-margin-left-42 {
  margin-left: 17.68421rem; }

.small-margin-left-43 {
  margin-left: 18.10526rem; }

.small-margin-left-44 {
  margin-left: 18.52632rem; }

.small-margin-left-45 {
  margin-left: 18.94737rem; }

.small-margin-left-46 {
  margin-left: 19.36842rem; }

.small-margin-left-47 {
  margin-left: 19.78947rem; }

.small-margin-left-48 {
  margin-left: 20.21053rem; }

.small-margin-0 {
  margin-top: 0;
  margin-bottom: 0; }

.small-margin-1 {
  margin-top: 0.42105rem;
  margin-bottom: 0.42105rem; }

.small-margin-2 {
  margin-top: 0.84211rem;
  margin-bottom: 0.84211rem; }

.small-margin-3 {
  margin-top: 1.26316rem;
  margin-bottom: 1.26316rem; }

.small-margin-4 {
  margin-top: 1.68421rem;
  margin-bottom: 1.68421rem; }

.small-margin-5 {
  margin-top: 2.10526rem;
  margin-bottom: 2.10526rem; }

.small-margin-6 {
  margin-top: 2.52632rem;
  margin-bottom: 2.52632rem; }

.small-margin-7 {
  margin-top: 2.94737rem;
  margin-bottom: 2.94737rem; }

.small-margin-8 {
  margin-top: 3.36842rem;
  margin-bottom: 3.36842rem; }

.small-margin-9 {
  margin-top: 3.78947rem;
  margin-bottom: 3.78947rem; }

.small-margin-10 {
  margin-top: 4.21053rem;
  margin-bottom: 4.21053rem; }

.small-margin-11 {
  margin-top: 4.63158rem;
  margin-bottom: 4.63158rem; }

.small-margin-12 {
  margin-top: 5.05263rem;
  margin-bottom: 5.05263rem; }

.small-margin-13 {
  margin-top: 5.47368rem;
  margin-bottom: 5.47368rem; }

.small-margin-14 {
  margin-top: 5.89474rem;
  margin-bottom: 5.89474rem; }

.small-margin-15 {
  margin-top: 6.31579rem;
  margin-bottom: 6.31579rem; }

.small-margin-16 {
  margin-top: 6.73684rem;
  margin-bottom: 6.73684rem; }

.small-margin-17 {
  margin-top: 7.15789rem;
  margin-bottom: 7.15789rem; }

.small-margin-18 {
  margin-top: 7.57895rem;
  margin-bottom: 7.57895rem; }

.small-margin-19 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.small-margin-20 {
  margin-top: 8.42105rem;
  margin-bottom: 8.42105rem; }

.small-margin-21 {
  margin-top: 8.84211rem;
  margin-bottom: 8.84211rem; }

.small-margin-22 {
  margin-top: 9.26316rem;
  margin-bottom: 9.26316rem; }

.small-margin-23 {
  margin-top: 9.68421rem;
  margin-bottom: 9.68421rem; }

.small-margin-24 {
  margin-top: 10.10526rem;
  margin-bottom: 10.10526rem; }

.small-margin-25 {
  margin-top: 10.52632rem;
  margin-bottom: 10.52632rem; }

.small-margin-26 {
  margin-top: 10.94737rem;
  margin-bottom: 10.94737rem; }

.small-margin-27 {
  margin-top: 11.36842rem;
  margin-bottom: 11.36842rem; }

.small-margin-28 {
  margin-top: 11.78947rem;
  margin-bottom: 11.78947rem; }

.small-margin-29 {
  margin-top: 12.21053rem;
  margin-bottom: 12.21053rem; }

.small-margin-30 {
  margin-top: 12.63158rem;
  margin-bottom: 12.63158rem; }

.small-margin-31 {
  margin-top: 13.05263rem;
  margin-bottom: 13.05263rem; }

.small-margin-32 {
  margin-top: 13.47368rem;
  margin-bottom: 13.47368rem; }

.small-margin-33 {
  margin-top: 13.89474rem;
  margin-bottom: 13.89474rem; }

.small-margin-34 {
  margin-top: 14.31579rem;
  margin-bottom: 14.31579rem; }

.small-margin-35 {
  margin-top: 14.73684rem;
  margin-bottom: 14.73684rem; }

.small-margin-36 {
  margin-top: 15.15789rem;
  margin-bottom: 15.15789rem; }

.small-margin-37 {
  margin-top: 15.57895rem;
  margin-bottom: 15.57895rem; }

.small-margin-38 {
  margin-top: 16rem;
  margin-bottom: 16rem; }

.small-margin-39 {
  margin-top: 16.42105rem;
  margin-bottom: 16.42105rem; }

.small-margin-40 {
  margin-top: 16.84211rem;
  margin-bottom: 16.84211rem; }

.small-margin-41 {
  margin-top: 17.26316rem;
  margin-bottom: 17.26316rem; }

.small-margin-42 {
  margin-top: 17.68421rem;
  margin-bottom: 17.68421rem; }

.small-margin-43 {
  margin-top: 18.10526rem;
  margin-bottom: 18.10526rem; }

.small-margin-44 {
  margin-top: 18.52632rem;
  margin-bottom: 18.52632rem; }

.small-margin-45 {
  margin-top: 18.94737rem;
  margin-bottom: 18.94737rem; }

.small-margin-46 {
  margin-top: 19.36842rem;
  margin-bottom: 19.36842rem; }

.small-margin-47 {
  margin-top: 19.78947rem;
  margin-bottom: 19.78947rem; }

.small-margin-48 {
  margin-top: 20.21053rem;
  margin-bottom: 20.21053rem; }

.small-padding-top-0 {
  padding-top: 0; }

.small-padding-top-1 {
  padding-top: 0.42105rem; }

.small-padding-top-2 {
  padding-top: 0.84211rem; }

.small-padding-top-3 {
  padding-top: 1.26316rem; }

.small-padding-top-4 {
  padding-top: 1.68421rem; }

.small-padding-top-5 {
  padding-top: 2.10526rem; }

.small-padding-top-6 {
  padding-top: 2.52632rem; }

.small-padding-top-7 {
  padding-top: 2.94737rem; }

.small-padding-top-8 {
  padding-top: 3.36842rem; }

.small-padding-top-9 {
  padding-top: 3.78947rem; }

.small-padding-top-10 {
  padding-top: 4.21053rem; }

.small-padding-top-11 {
  padding-top: 4.63158rem; }

.small-padding-top-12 {
  padding-top: 5.05263rem; }

.small-padding-top-13 {
  padding-top: 5.47368rem; }

.small-padding-top-14 {
  padding-top: 5.89474rem; }

.small-padding-top-15 {
  padding-top: 6.31579rem; }

.small-padding-top-16 {
  padding-top: 6.73684rem; }

.small-padding-top-17 {
  padding-top: 7.15789rem; }

.small-padding-top-18 {
  padding-top: 7.57895rem; }

.small-padding-top-19 {
  padding-top: 8rem; }

.small-padding-top-20 {
  padding-top: 8.42105rem; }

.small-padding-top-21 {
  padding-top: 8.84211rem; }

.small-padding-top-22 {
  padding-top: 9.26316rem; }

.small-padding-top-23 {
  padding-top: 9.68421rem; }

.small-padding-top-24 {
  padding-top: 10.10526rem; }

.small-padding-top-25 {
  padding-top: 10.52632rem; }

.small-padding-top-26 {
  padding-top: 10.94737rem; }

.small-padding-top-27 {
  padding-top: 11.36842rem; }

.small-padding-top-28 {
  padding-top: 11.78947rem; }

.small-padding-top-29 {
  padding-top: 12.21053rem; }

.small-padding-top-30 {
  padding-top: 12.63158rem; }

.small-padding-top-31 {
  padding-top: 13.05263rem; }

.small-padding-top-32 {
  padding-top: 13.47368rem; }

.small-padding-top-33 {
  padding-top: 13.89474rem; }

.small-padding-top-34 {
  padding-top: 14.31579rem; }

.small-padding-top-35 {
  padding-top: 14.73684rem; }

.small-padding-top-36 {
  padding-top: 15.15789rem; }

.small-padding-top-37 {
  padding-top: 15.57895rem; }

.small-padding-top-38 {
  padding-top: 16rem; }

.small-padding-top-39 {
  padding-top: 16.42105rem; }

.small-padding-top-40 {
  padding-top: 16.84211rem; }

.small-padding-top-41 {
  padding-top: 17.26316rem; }

.small-padding-top-42 {
  padding-top: 17.68421rem; }

.small-padding-top-43 {
  padding-top: 18.10526rem; }

.small-padding-top-44 {
  padding-top: 18.52632rem; }

.small-padding-top-45 {
  padding-top: 18.94737rem; }

.small-padding-top-46 {
  padding-top: 19.36842rem; }

.small-padding-top-47 {
  padding-top: 19.78947rem; }

.small-padding-top-48 {
  padding-top: 20.21053rem; }

.small-padding-bottom-0 {
  padding-bottom: 0; }

.small-padding-bottom-1 {
  padding-bottom: 0.42105rem; }

.small-padding-bottom-2 {
  padding-bottom: 0.84211rem; }

.small-padding-bottom-3 {
  padding-bottom: 1.26316rem; }

.small-padding-bottom-4 {
  padding-bottom: 1.68421rem; }

.small-padding-bottom-5 {
  padding-bottom: 2.10526rem; }

.small-padding-bottom-6 {
  padding-bottom: 2.52632rem; }

.small-padding-bottom-7 {
  padding-bottom: 2.94737rem; }

.small-padding-bottom-8 {
  padding-bottom: 3.36842rem; }

.small-padding-bottom-9 {
  padding-bottom: 3.78947rem; }

.small-padding-bottom-10 {
  padding-bottom: 4.21053rem; }

.small-padding-bottom-11 {
  padding-bottom: 4.63158rem; }

.small-padding-bottom-12 {
  padding-bottom: 5.05263rem; }

.small-padding-bottom-13 {
  padding-bottom: 5.47368rem; }

.small-padding-bottom-14 {
  padding-bottom: 5.89474rem; }

.small-padding-bottom-15 {
  padding-bottom: 6.31579rem; }

.small-padding-bottom-16 {
  padding-bottom: 6.73684rem; }

.small-padding-bottom-17 {
  padding-bottom: 7.15789rem; }

.small-padding-bottom-18 {
  padding-bottom: 7.57895rem; }

.small-padding-bottom-19 {
  padding-bottom: 8rem; }

.small-padding-bottom-20 {
  padding-bottom: 8.42105rem; }

.small-padding-bottom-21 {
  padding-bottom: 8.84211rem; }

.small-padding-bottom-22 {
  padding-bottom: 9.26316rem; }

.small-padding-bottom-23 {
  padding-bottom: 9.68421rem; }

.small-padding-bottom-24 {
  padding-bottom: 10.10526rem; }

.small-padding-bottom-25 {
  padding-bottom: 10.52632rem; }

.small-padding-bottom-26 {
  padding-bottom: 10.94737rem; }

.small-padding-bottom-27 {
  padding-bottom: 11.36842rem; }

.small-padding-bottom-28 {
  padding-bottom: 11.78947rem; }

.small-padding-bottom-29 {
  padding-bottom: 12.21053rem; }

.small-padding-bottom-30 {
  padding-bottom: 12.63158rem; }

.small-padding-bottom-31 {
  padding-bottom: 13.05263rem; }

.small-padding-bottom-32 {
  padding-bottom: 13.47368rem; }

.small-padding-bottom-33 {
  padding-bottom: 13.89474rem; }

.small-padding-bottom-34 {
  padding-bottom: 14.31579rem; }

.small-padding-bottom-35 {
  padding-bottom: 14.73684rem; }

.small-padding-bottom-36 {
  padding-bottom: 15.15789rem; }

.small-padding-bottom-37 {
  padding-bottom: 15.57895rem; }

.small-padding-bottom-38 {
  padding-bottom: 16rem; }

.small-padding-bottom-39 {
  padding-bottom: 16.42105rem; }

.small-padding-bottom-40 {
  padding-bottom: 16.84211rem; }

.small-padding-bottom-41 {
  padding-bottom: 17.26316rem; }

.small-padding-bottom-42 {
  padding-bottom: 17.68421rem; }

.small-padding-bottom-43 {
  padding-bottom: 18.10526rem; }

.small-padding-bottom-44 {
  padding-bottom: 18.52632rem; }

.small-padding-bottom-45 {
  padding-bottom: 18.94737rem; }

.small-padding-bottom-46 {
  padding-bottom: 19.36842rem; }

.small-padding-bottom-47 {
  padding-bottom: 19.78947rem; }

.small-padding-bottom-48 {
  padding-bottom: 20.21053rem; }

.small-padding-right-0 {
  padding-right: 0; }

.small-padding-right-1 {
  padding-right: 0.42105rem; }

.small-padding-right-2 {
  padding-right: 0.84211rem; }

.small-padding-right-3 {
  padding-right: 1.26316rem; }

.small-padding-right-4 {
  padding-right: 1.68421rem; }

.small-padding-right-5 {
  padding-right: 2.10526rem; }

.small-padding-right-6 {
  padding-right: 2.52632rem; }

.small-padding-right-7 {
  padding-right: 2.94737rem; }

.small-padding-right-8 {
  padding-right: 3.36842rem; }

.small-padding-right-9 {
  padding-right: 3.78947rem; }

.small-padding-right-10 {
  padding-right: 4.21053rem; }

.small-padding-right-11 {
  padding-right: 4.63158rem; }

.small-padding-right-12 {
  padding-right: 5.05263rem; }

.small-padding-right-13 {
  padding-right: 5.47368rem; }

.small-padding-right-14 {
  padding-right: 5.89474rem; }

.small-padding-right-15 {
  padding-right: 6.31579rem; }

.small-padding-right-16 {
  padding-right: 6.73684rem; }

.small-padding-right-17 {
  padding-right: 7.15789rem; }

.small-padding-right-18 {
  padding-right: 7.57895rem; }

.small-padding-right-19 {
  padding-right: 8rem; }

.small-padding-right-20 {
  padding-right: 8.42105rem; }

.small-padding-right-21 {
  padding-right: 8.84211rem; }

.small-padding-right-22 {
  padding-right: 9.26316rem; }

.small-padding-right-23 {
  padding-right: 9.68421rem; }

.small-padding-right-24 {
  padding-right: 10.10526rem; }

.small-padding-right-25 {
  padding-right: 10.52632rem; }

.small-padding-right-26 {
  padding-right: 10.94737rem; }

.small-padding-right-27 {
  padding-right: 11.36842rem; }

.small-padding-right-28 {
  padding-right: 11.78947rem; }

.small-padding-right-29 {
  padding-right: 12.21053rem; }

.small-padding-right-30 {
  padding-right: 12.63158rem; }

.small-padding-right-31 {
  padding-right: 13.05263rem; }

.small-padding-right-32 {
  padding-right: 13.47368rem; }

.small-padding-right-33 {
  padding-right: 13.89474rem; }

.small-padding-right-34 {
  padding-right: 14.31579rem; }

.small-padding-right-35 {
  padding-right: 14.73684rem; }

.small-padding-right-36 {
  padding-right: 15.15789rem; }

.small-padding-right-37 {
  padding-right: 15.57895rem; }

.small-padding-right-38 {
  padding-right: 16rem; }

.small-padding-right-39 {
  padding-right: 16.42105rem; }

.small-padding-right-40 {
  padding-right: 16.84211rem; }

.small-padding-right-41 {
  padding-right: 17.26316rem; }

.small-padding-right-42 {
  padding-right: 17.68421rem; }

.small-padding-right-43 {
  padding-right: 18.10526rem; }

.small-padding-right-44 {
  padding-right: 18.52632rem; }

.small-padding-right-45 {
  padding-right: 18.94737rem; }

.small-padding-right-46 {
  padding-right: 19.36842rem; }

.small-padding-right-47 {
  padding-right: 19.78947rem; }

.small-padding-right-48 {
  padding-right: 20.21053rem; }

.small-padding-left-0 {
  padding-left: 0; }

.small-padding-left-1 {
  padding-left: 0.42105rem; }

.small-padding-left-2 {
  padding-left: 0.84211rem; }

.small-padding-left-3 {
  padding-left: 1.26316rem; }

.small-padding-left-4 {
  padding-left: 1.68421rem; }

.small-padding-left-5 {
  padding-left: 2.10526rem; }

.small-padding-left-6 {
  padding-left: 2.52632rem; }

.small-padding-left-7 {
  padding-left: 2.94737rem; }

.small-padding-left-8 {
  padding-left: 3.36842rem; }

.small-padding-left-9 {
  padding-left: 3.78947rem; }

.small-padding-left-10 {
  padding-left: 4.21053rem; }

.small-padding-left-11 {
  padding-left: 4.63158rem; }

.small-padding-left-12 {
  padding-left: 5.05263rem; }

.small-padding-left-13 {
  padding-left: 5.47368rem; }

.small-padding-left-14 {
  padding-left: 5.89474rem; }

.small-padding-left-15 {
  padding-left: 6.31579rem; }

.small-padding-left-16 {
  padding-left: 6.73684rem; }

.small-padding-left-17 {
  padding-left: 7.15789rem; }

.small-padding-left-18 {
  padding-left: 7.57895rem; }

.small-padding-left-19 {
  padding-left: 8rem; }

.small-padding-left-20 {
  padding-left: 8.42105rem; }

.small-padding-left-21 {
  padding-left: 8.84211rem; }

.small-padding-left-22 {
  padding-left: 9.26316rem; }

.small-padding-left-23 {
  padding-left: 9.68421rem; }

.small-padding-left-24 {
  padding-left: 10.10526rem; }

.small-padding-left-25 {
  padding-left: 10.52632rem; }

.small-padding-left-26 {
  padding-left: 10.94737rem; }

.small-padding-left-27 {
  padding-left: 11.36842rem; }

.small-padding-left-28 {
  padding-left: 11.78947rem; }

.small-padding-left-29 {
  padding-left: 12.21053rem; }

.small-padding-left-30 {
  padding-left: 12.63158rem; }

.small-padding-left-31 {
  padding-left: 13.05263rem; }

.small-padding-left-32 {
  padding-left: 13.47368rem; }

.small-padding-left-33 {
  padding-left: 13.89474rem; }

.small-padding-left-34 {
  padding-left: 14.31579rem; }

.small-padding-left-35 {
  padding-left: 14.73684rem; }

.small-padding-left-36 {
  padding-left: 15.15789rem; }

.small-padding-left-37 {
  padding-left: 15.57895rem; }

.small-padding-left-38 {
  padding-left: 16rem; }

.small-padding-left-39 {
  padding-left: 16.42105rem; }

.small-padding-left-40 {
  padding-left: 16.84211rem; }

.small-padding-left-41 {
  padding-left: 17.26316rem; }

.small-padding-left-42 {
  padding-left: 17.68421rem; }

.small-padding-left-43 {
  padding-left: 18.10526rem; }

.small-padding-left-44 {
  padding-left: 18.52632rem; }

.small-padding-left-45 {
  padding-left: 18.94737rem; }

.small-padding-left-46 {
  padding-left: 19.36842rem; }

.small-padding-left-47 {
  padding-left: 19.78947rem; }

.small-padding-left-48 {
  padding-left: 20.21053rem; }

.small-padding-0 {
  padding-top: 0;
  padding-bottom: 0; }

.small-padding-1 {
  padding-top: 0.42105rem;
  padding-bottom: 0.42105rem; }

.small-padding-2 {
  padding-top: 0.84211rem;
  padding-bottom: 0.84211rem; }

.small-padding-3 {
  padding-top: 1.26316rem;
  padding-bottom: 1.26316rem; }

.small-padding-4 {
  padding-top: 1.68421rem;
  padding-bottom: 1.68421rem; }

.small-padding-5 {
  padding-top: 2.10526rem;
  padding-bottom: 2.10526rem; }

.small-padding-6 {
  padding-top: 2.52632rem;
  padding-bottom: 2.52632rem; }

.small-padding-7 {
  padding-top: 2.94737rem;
  padding-bottom: 2.94737rem; }

.small-padding-8 {
  padding-top: 3.36842rem;
  padding-bottom: 3.36842rem; }

.small-padding-9 {
  padding-top: 3.78947rem;
  padding-bottom: 3.78947rem; }

.small-padding-10 {
  padding-top: 4.21053rem;
  padding-bottom: 4.21053rem; }

.small-padding-11 {
  padding-top: 4.63158rem;
  padding-bottom: 4.63158rem; }

.small-padding-12 {
  padding-top: 5.05263rem;
  padding-bottom: 5.05263rem; }

.small-padding-13 {
  padding-top: 5.47368rem;
  padding-bottom: 5.47368rem; }

.small-padding-14 {
  padding-top: 5.89474rem;
  padding-bottom: 5.89474rem; }

.small-padding-15 {
  padding-top: 6.31579rem;
  padding-bottom: 6.31579rem; }

.small-padding-16 {
  padding-top: 6.73684rem;
  padding-bottom: 6.73684rem; }

.small-padding-17 {
  padding-top: 7.15789rem;
  padding-bottom: 7.15789rem; }

.small-padding-18 {
  padding-top: 7.57895rem;
  padding-bottom: 7.57895rem; }

.small-padding-19 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.small-padding-20 {
  padding-top: 8.42105rem;
  padding-bottom: 8.42105rem; }

.small-padding-21 {
  padding-top: 8.84211rem;
  padding-bottom: 8.84211rem; }

.small-padding-22 {
  padding-top: 9.26316rem;
  padding-bottom: 9.26316rem; }

.small-padding-23 {
  padding-top: 9.68421rem;
  padding-bottom: 9.68421rem; }

.small-padding-24 {
  padding-top: 10.10526rem;
  padding-bottom: 10.10526rem; }

.small-padding-25 {
  padding-top: 10.52632rem;
  padding-bottom: 10.52632rem; }

.small-padding-26 {
  padding-top: 10.94737rem;
  padding-bottom: 10.94737rem; }

.small-padding-27 {
  padding-top: 11.36842rem;
  padding-bottom: 11.36842rem; }

.small-padding-28 {
  padding-top: 11.78947rem;
  padding-bottom: 11.78947rem; }

.small-padding-29 {
  padding-top: 12.21053rem;
  padding-bottom: 12.21053rem; }

.small-padding-30 {
  padding-top: 12.63158rem;
  padding-bottom: 12.63158rem; }

.small-padding-31 {
  padding-top: 13.05263rem;
  padding-bottom: 13.05263rem; }

.small-padding-32 {
  padding-top: 13.47368rem;
  padding-bottom: 13.47368rem; }

.small-padding-33 {
  padding-top: 13.89474rem;
  padding-bottom: 13.89474rem; }

.small-padding-34 {
  padding-top: 14.31579rem;
  padding-bottom: 14.31579rem; }

.small-padding-35 {
  padding-top: 14.73684rem;
  padding-bottom: 14.73684rem; }

.small-padding-36 {
  padding-top: 15.15789rem;
  padding-bottom: 15.15789rem; }

.small-padding-37 {
  padding-top: 15.57895rem;
  padding-bottom: 15.57895rem; }

.small-padding-38 {
  padding-top: 16rem;
  padding-bottom: 16rem; }

.small-padding-39 {
  padding-top: 16.42105rem;
  padding-bottom: 16.42105rem; }

.small-padding-40 {
  padding-top: 16.84211rem;
  padding-bottom: 16.84211rem; }

.small-padding-41 {
  padding-top: 17.26316rem;
  padding-bottom: 17.26316rem; }

.small-padding-42 {
  padding-top: 17.68421rem;
  padding-bottom: 17.68421rem; }

.small-padding-43 {
  padding-top: 18.10526rem;
  padding-bottom: 18.10526rem; }

.small-padding-44 {
  padding-top: 18.52632rem;
  padding-bottom: 18.52632rem; }

.small-padding-45 {
  padding-top: 18.94737rem;
  padding-bottom: 18.94737rem; }

.small-padding-46 {
  padding-top: 19.36842rem;
  padding-bottom: 19.36842rem; }

.small-padding-47 {
  padding-top: 19.78947rem;
  padding-bottom: 19.78947rem; }

.small-padding-48 {
  padding-top: 20.21053rem;
  padding-bottom: 20.21053rem; }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .medium-margin-top-0 {
    margin-top: 0; }
  .medium-margin-top-1 {
    margin-top: 0.42105rem; }
  .medium-margin-top-2 {
    margin-top: 0.84211rem; }
  .medium-margin-top-3 {
    margin-top: 1.26316rem; }
  .medium-margin-top-4 {
    margin-top: 1.68421rem; }
  .medium-margin-top-5 {
    margin-top: 2.10526rem; }
  .medium-margin-top-6 {
    margin-top: 2.52632rem; }
  .medium-margin-top-7 {
    margin-top: 2.94737rem; }
  .medium-margin-top-8 {
    margin-top: 3.36842rem; }
  .medium-margin-top-9 {
    margin-top: 3.78947rem; }
  .medium-margin-top-10 {
    margin-top: 4.21053rem; }
  .medium-margin-top-11 {
    margin-top: 4.63158rem; }
  .medium-margin-top-12 {
    margin-top: 5.05263rem; }
  .medium-margin-top-13 {
    margin-top: 5.47368rem; }
  .medium-margin-top-14 {
    margin-top: 5.89474rem; }
  .medium-margin-top-15 {
    margin-top: 6.31579rem; }
  .medium-margin-top-16 {
    margin-top: 6.73684rem; }
  .medium-margin-top-17 {
    margin-top: 7.15789rem; }
  .medium-margin-top-18 {
    margin-top: 7.57895rem; }
  .medium-margin-top-19 {
    margin-top: 8rem; }
  .medium-margin-top-20 {
    margin-top: 8.42105rem; }
  .medium-margin-top-21 {
    margin-top: 8.84211rem; }
  .medium-margin-top-22 {
    margin-top: 9.26316rem; }
  .medium-margin-top-23 {
    margin-top: 9.68421rem; }
  .medium-margin-top-24 {
    margin-top: 10.10526rem; }
  .medium-margin-top-25 {
    margin-top: 10.52632rem; }
  .medium-margin-top-26 {
    margin-top: 10.94737rem; }
  .medium-margin-top-27 {
    margin-top: 11.36842rem; }
  .medium-margin-top-28 {
    margin-top: 11.78947rem; }
  .medium-margin-top-29 {
    margin-top: 12.21053rem; }
  .medium-margin-top-30 {
    margin-top: 12.63158rem; }
  .medium-margin-top-31 {
    margin-top: 13.05263rem; }
  .medium-margin-top-32 {
    margin-top: 13.47368rem; }
  .medium-margin-top-33 {
    margin-top: 13.89474rem; }
  .medium-margin-top-34 {
    margin-top: 14.31579rem; }
  .medium-margin-top-35 {
    margin-top: 14.73684rem; }
  .medium-margin-top-36 {
    margin-top: 15.15789rem; }
  .medium-margin-top-37 {
    margin-top: 15.57895rem; }
  .medium-margin-top-38 {
    margin-top: 16rem; }
  .medium-margin-top-39 {
    margin-top: 16.42105rem; }
  .medium-margin-top-40 {
    margin-top: 16.84211rem; }
  .medium-margin-top-41 {
    margin-top: 17.26316rem; }
  .medium-margin-top-42 {
    margin-top: 17.68421rem; }
  .medium-margin-top-43 {
    margin-top: 18.10526rem; }
  .medium-margin-top-44 {
    margin-top: 18.52632rem; }
  .medium-margin-top-45 {
    margin-top: 18.94737rem; }
  .medium-margin-top-46 {
    margin-top: 19.36842rem; }
  .medium-margin-top-47 {
    margin-top: 19.78947rem; }
  .medium-margin-top-48 {
    margin-top: 20.21053rem; }
  .medium-margin-bottom-0 {
    margin-bottom: 0; }
  .medium-margin-bottom-1 {
    margin-bottom: 0.42105rem; }
  .medium-margin-bottom-2 {
    margin-bottom: 0.84211rem; }
  .medium-margin-bottom-3 {
    margin-bottom: 1.26316rem; }
  .medium-margin-bottom-4 {
    margin-bottom: 1.68421rem; }
  .medium-margin-bottom-5 {
    margin-bottom: 2.10526rem; }
  .medium-margin-bottom-6 {
    margin-bottom: 2.52632rem; }
  .medium-margin-bottom-7 {
    margin-bottom: 2.94737rem; }
  .medium-margin-bottom-8 {
    margin-bottom: 3.36842rem; }
  .medium-margin-bottom-9 {
    margin-bottom: 3.78947rem; }
  .medium-margin-bottom-10 {
    margin-bottom: 4.21053rem; }
  .medium-margin-bottom-11 {
    margin-bottom: 4.63158rem; }
  .medium-margin-bottom-12 {
    margin-bottom: 5.05263rem; }
  .medium-margin-bottom-13 {
    margin-bottom: 5.47368rem; }
  .medium-margin-bottom-14 {
    margin-bottom: 5.89474rem; }
  .medium-margin-bottom-15 {
    margin-bottom: 6.31579rem; }
  .medium-margin-bottom-16 {
    margin-bottom: 6.73684rem; }
  .medium-margin-bottom-17 {
    margin-bottom: 7.15789rem; }
  .medium-margin-bottom-18 {
    margin-bottom: 7.57895rem; }
  .medium-margin-bottom-19 {
    margin-bottom: 8rem; }
  .medium-margin-bottom-20 {
    margin-bottom: 8.42105rem; }
  .medium-margin-bottom-21 {
    margin-bottom: 8.84211rem; }
  .medium-margin-bottom-22 {
    margin-bottom: 9.26316rem; }
  .medium-margin-bottom-23 {
    margin-bottom: 9.68421rem; }
  .medium-margin-bottom-24 {
    margin-bottom: 10.10526rem; }
  .medium-margin-bottom-25 {
    margin-bottom: 10.52632rem; }
  .medium-margin-bottom-26 {
    margin-bottom: 10.94737rem; }
  .medium-margin-bottom-27 {
    margin-bottom: 11.36842rem; }
  .medium-margin-bottom-28 {
    margin-bottom: 11.78947rem; }
  .medium-margin-bottom-29 {
    margin-bottom: 12.21053rem; }
  .medium-margin-bottom-30 {
    margin-bottom: 12.63158rem; }
  .medium-margin-bottom-31 {
    margin-bottom: 13.05263rem; }
  .medium-margin-bottom-32 {
    margin-bottom: 13.47368rem; }
  .medium-margin-bottom-33 {
    margin-bottom: 13.89474rem; }
  .medium-margin-bottom-34 {
    margin-bottom: 14.31579rem; }
  .medium-margin-bottom-35 {
    margin-bottom: 14.73684rem; }
  .medium-margin-bottom-36 {
    margin-bottom: 15.15789rem; }
  .medium-margin-bottom-37 {
    margin-bottom: 15.57895rem; }
  .medium-margin-bottom-38 {
    margin-bottom: 16rem; }
  .medium-margin-bottom-39 {
    margin-bottom: 16.42105rem; }
  .medium-margin-bottom-40 {
    margin-bottom: 16.84211rem; }
  .medium-margin-bottom-41 {
    margin-bottom: 17.26316rem; }
  .medium-margin-bottom-42 {
    margin-bottom: 17.68421rem; }
  .medium-margin-bottom-43 {
    margin-bottom: 18.10526rem; }
  .medium-margin-bottom-44 {
    margin-bottom: 18.52632rem; }
  .medium-margin-bottom-45 {
    margin-bottom: 18.94737rem; }
  .medium-margin-bottom-46 {
    margin-bottom: 19.36842rem; }
  .medium-margin-bottom-47 {
    margin-bottom: 19.78947rem; }
  .medium-margin-bottom-48 {
    margin-bottom: 20.21053rem; }
  .medium-margin-right-0 {
    margin-right: 0; }
  .medium-margin-right-1 {
    margin-right: 0.42105rem; }
  .medium-margin-right-2 {
    margin-right: 0.84211rem; }
  .medium-margin-right-3 {
    margin-right: 1.26316rem; }
  .medium-margin-right-4 {
    margin-right: 1.68421rem; }
  .medium-margin-right-5 {
    margin-right: 2.10526rem; }
  .medium-margin-right-6 {
    margin-right: 2.52632rem; }
  .medium-margin-right-7 {
    margin-right: 2.94737rem; }
  .medium-margin-right-8 {
    margin-right: 3.36842rem; }
  .medium-margin-right-9 {
    margin-right: 3.78947rem; }
  .medium-margin-right-10 {
    margin-right: 4.21053rem; }
  .medium-margin-right-11 {
    margin-right: 4.63158rem; }
  .medium-margin-right-12 {
    margin-right: 5.05263rem; }
  .medium-margin-right-13 {
    margin-right: 5.47368rem; }
  .medium-margin-right-14 {
    margin-right: 5.89474rem; }
  .medium-margin-right-15 {
    margin-right: 6.31579rem; }
  .medium-margin-right-16 {
    margin-right: 6.73684rem; }
  .medium-margin-right-17 {
    margin-right: 7.15789rem; }
  .medium-margin-right-18 {
    margin-right: 7.57895rem; }
  .medium-margin-right-19 {
    margin-right: 8rem; }
  .medium-margin-right-20 {
    margin-right: 8.42105rem; }
  .medium-margin-right-21 {
    margin-right: 8.84211rem; }
  .medium-margin-right-22 {
    margin-right: 9.26316rem; }
  .medium-margin-right-23 {
    margin-right: 9.68421rem; }
  .medium-margin-right-24 {
    margin-right: 10.10526rem; }
  .medium-margin-right-25 {
    margin-right: 10.52632rem; }
  .medium-margin-right-26 {
    margin-right: 10.94737rem; }
  .medium-margin-right-27 {
    margin-right: 11.36842rem; }
  .medium-margin-right-28 {
    margin-right: 11.78947rem; }
  .medium-margin-right-29 {
    margin-right: 12.21053rem; }
  .medium-margin-right-30 {
    margin-right: 12.63158rem; }
  .medium-margin-right-31 {
    margin-right: 13.05263rem; }
  .medium-margin-right-32 {
    margin-right: 13.47368rem; }
  .medium-margin-right-33 {
    margin-right: 13.89474rem; }
  .medium-margin-right-34 {
    margin-right: 14.31579rem; }
  .medium-margin-right-35 {
    margin-right: 14.73684rem; }
  .medium-margin-right-36 {
    margin-right: 15.15789rem; }
  .medium-margin-right-37 {
    margin-right: 15.57895rem; }
  .medium-margin-right-38 {
    margin-right: 16rem; }
  .medium-margin-right-39 {
    margin-right: 16.42105rem; }
  .medium-margin-right-40 {
    margin-right: 16.84211rem; }
  .medium-margin-right-41 {
    margin-right: 17.26316rem; }
  .medium-margin-right-42 {
    margin-right: 17.68421rem; }
  .medium-margin-right-43 {
    margin-right: 18.10526rem; }
  .medium-margin-right-44 {
    margin-right: 18.52632rem; }
  .medium-margin-right-45 {
    margin-right: 18.94737rem; }
  .medium-margin-right-46 {
    margin-right: 19.36842rem; }
  .medium-margin-right-47 {
    margin-right: 19.78947rem; }
  .medium-margin-right-48 {
    margin-right: 20.21053rem; }
  .medium-margin-left-0 {
    margin-left: 0; }
  .medium-margin-left-1 {
    margin-left: 0.42105rem; }
  .medium-margin-left-2 {
    margin-left: 0.84211rem; }
  .medium-margin-left-3 {
    margin-left: 1.26316rem; }
  .medium-margin-left-4 {
    margin-left: 1.68421rem; }
  .medium-margin-left-5 {
    margin-left: 2.10526rem; }
  .medium-margin-left-6 {
    margin-left: 2.52632rem; }
  .medium-margin-left-7 {
    margin-left: 2.94737rem; }
  .medium-margin-left-8 {
    margin-left: 3.36842rem; }
  .medium-margin-left-9 {
    margin-left: 3.78947rem; }
  .medium-margin-left-10 {
    margin-left: 4.21053rem; }
  .medium-margin-left-11 {
    margin-left: 4.63158rem; }
  .medium-margin-left-12 {
    margin-left: 5.05263rem; }
  .medium-margin-left-13 {
    margin-left: 5.47368rem; }
  .medium-margin-left-14 {
    margin-left: 5.89474rem; }
  .medium-margin-left-15 {
    margin-left: 6.31579rem; }
  .medium-margin-left-16 {
    margin-left: 6.73684rem; }
  .medium-margin-left-17 {
    margin-left: 7.15789rem; }
  .medium-margin-left-18 {
    margin-left: 7.57895rem; }
  .medium-margin-left-19 {
    margin-left: 8rem; }
  .medium-margin-left-20 {
    margin-left: 8.42105rem; }
  .medium-margin-left-21 {
    margin-left: 8.84211rem; }
  .medium-margin-left-22 {
    margin-left: 9.26316rem; }
  .medium-margin-left-23 {
    margin-left: 9.68421rem; }
  .medium-margin-left-24 {
    margin-left: 10.10526rem; }
  .medium-margin-left-25 {
    margin-left: 10.52632rem; }
  .medium-margin-left-26 {
    margin-left: 10.94737rem; }
  .medium-margin-left-27 {
    margin-left: 11.36842rem; }
  .medium-margin-left-28 {
    margin-left: 11.78947rem; }
  .medium-margin-left-29 {
    margin-left: 12.21053rem; }
  .medium-margin-left-30 {
    margin-left: 12.63158rem; }
  .medium-margin-left-31 {
    margin-left: 13.05263rem; }
  .medium-margin-left-32 {
    margin-left: 13.47368rem; }
  .medium-margin-left-33 {
    margin-left: 13.89474rem; }
  .medium-margin-left-34 {
    margin-left: 14.31579rem; }
  .medium-margin-left-35 {
    margin-left: 14.73684rem; }
  .medium-margin-left-36 {
    margin-left: 15.15789rem; }
  .medium-margin-left-37 {
    margin-left: 15.57895rem; }
  .medium-margin-left-38 {
    margin-left: 16rem; }
  .medium-margin-left-39 {
    margin-left: 16.42105rem; }
  .medium-margin-left-40 {
    margin-left: 16.84211rem; }
  .medium-margin-left-41 {
    margin-left: 17.26316rem; }
  .medium-margin-left-42 {
    margin-left: 17.68421rem; }
  .medium-margin-left-43 {
    margin-left: 18.10526rem; }
  .medium-margin-left-44 {
    margin-left: 18.52632rem; }
  .medium-margin-left-45 {
    margin-left: 18.94737rem; }
  .medium-margin-left-46 {
    margin-left: 19.36842rem; }
  .medium-margin-left-47 {
    margin-left: 19.78947rem; }
  .medium-margin-left-48 {
    margin-left: 20.21053rem; }
  .medium-margin-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .medium-margin-1 {
    margin-top: 0.42105rem;
    margin-bottom: 0.42105rem; }
  .medium-margin-2 {
    margin-top: 0.84211rem;
    margin-bottom: 0.84211rem; }
  .medium-margin-3 {
    margin-top: 1.26316rem;
    margin-bottom: 1.26316rem; }
  .medium-margin-4 {
    margin-top: 1.68421rem;
    margin-bottom: 1.68421rem; }
  .medium-margin-5 {
    margin-top: 2.10526rem;
    margin-bottom: 2.10526rem; }
  .medium-margin-6 {
    margin-top: 2.52632rem;
    margin-bottom: 2.52632rem; }
  .medium-margin-7 {
    margin-top: 2.94737rem;
    margin-bottom: 2.94737rem; }
  .medium-margin-8 {
    margin-top: 3.36842rem;
    margin-bottom: 3.36842rem; }
  .medium-margin-9 {
    margin-top: 3.78947rem;
    margin-bottom: 3.78947rem; }
  .medium-margin-10 {
    margin-top: 4.21053rem;
    margin-bottom: 4.21053rem; }
  .medium-margin-11 {
    margin-top: 4.63158rem;
    margin-bottom: 4.63158rem; }
  .medium-margin-12 {
    margin-top: 5.05263rem;
    margin-bottom: 5.05263rem; }
  .medium-margin-13 {
    margin-top: 5.47368rem;
    margin-bottom: 5.47368rem; }
  .medium-margin-14 {
    margin-top: 5.89474rem;
    margin-bottom: 5.89474rem; }
  .medium-margin-15 {
    margin-top: 6.31579rem;
    margin-bottom: 6.31579rem; }
  .medium-margin-16 {
    margin-top: 6.73684rem;
    margin-bottom: 6.73684rem; }
  .medium-margin-17 {
    margin-top: 7.15789rem;
    margin-bottom: 7.15789rem; }
  .medium-margin-18 {
    margin-top: 7.57895rem;
    margin-bottom: 7.57895rem; }
  .medium-margin-19 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .medium-margin-20 {
    margin-top: 8.42105rem;
    margin-bottom: 8.42105rem; }
  .medium-margin-21 {
    margin-top: 8.84211rem;
    margin-bottom: 8.84211rem; }
  .medium-margin-22 {
    margin-top: 9.26316rem;
    margin-bottom: 9.26316rem; }
  .medium-margin-23 {
    margin-top: 9.68421rem;
    margin-bottom: 9.68421rem; }
  .medium-margin-24 {
    margin-top: 10.10526rem;
    margin-bottom: 10.10526rem; }
  .medium-margin-25 {
    margin-top: 10.52632rem;
    margin-bottom: 10.52632rem; }
  .medium-margin-26 {
    margin-top: 10.94737rem;
    margin-bottom: 10.94737rem; }
  .medium-margin-27 {
    margin-top: 11.36842rem;
    margin-bottom: 11.36842rem; }
  .medium-margin-28 {
    margin-top: 11.78947rem;
    margin-bottom: 11.78947rem; }
  .medium-margin-29 {
    margin-top: 12.21053rem;
    margin-bottom: 12.21053rem; }
  .medium-margin-30 {
    margin-top: 12.63158rem;
    margin-bottom: 12.63158rem; }
  .medium-margin-31 {
    margin-top: 13.05263rem;
    margin-bottom: 13.05263rem; }
  .medium-margin-32 {
    margin-top: 13.47368rem;
    margin-bottom: 13.47368rem; }
  .medium-margin-33 {
    margin-top: 13.89474rem;
    margin-bottom: 13.89474rem; }
  .medium-margin-34 {
    margin-top: 14.31579rem;
    margin-bottom: 14.31579rem; }
  .medium-margin-35 {
    margin-top: 14.73684rem;
    margin-bottom: 14.73684rem; }
  .medium-margin-36 {
    margin-top: 15.15789rem;
    margin-bottom: 15.15789rem; }
  .medium-margin-37 {
    margin-top: 15.57895rem;
    margin-bottom: 15.57895rem; }
  .medium-margin-38 {
    margin-top: 16rem;
    margin-bottom: 16rem; }
  .medium-margin-39 {
    margin-top: 16.42105rem;
    margin-bottom: 16.42105rem; }
  .medium-margin-40 {
    margin-top: 16.84211rem;
    margin-bottom: 16.84211rem; }
  .medium-margin-41 {
    margin-top: 17.26316rem;
    margin-bottom: 17.26316rem; }
  .medium-margin-42 {
    margin-top: 17.68421rem;
    margin-bottom: 17.68421rem; }
  .medium-margin-43 {
    margin-top: 18.10526rem;
    margin-bottom: 18.10526rem; }
  .medium-margin-44 {
    margin-top: 18.52632rem;
    margin-bottom: 18.52632rem; }
  .medium-margin-45 {
    margin-top: 18.94737rem;
    margin-bottom: 18.94737rem; }
  .medium-margin-46 {
    margin-top: 19.36842rem;
    margin-bottom: 19.36842rem; }
  .medium-margin-47 {
    margin-top: 19.78947rem;
    margin-bottom: 19.78947rem; }
  .medium-margin-48 {
    margin-top: 20.21053rem;
    margin-bottom: 20.21053rem; }
  .medium-padding-top-0 {
    padding-top: 0; }
  .medium-padding-top-1 {
    padding-top: 0.42105rem; }
  .medium-padding-top-2 {
    padding-top: 0.84211rem; }
  .medium-padding-top-3 {
    padding-top: 1.26316rem; }
  .medium-padding-top-4 {
    padding-top: 1.68421rem; }
  .medium-padding-top-5 {
    padding-top: 2.10526rem; }
  .medium-padding-top-6 {
    padding-top: 2.52632rem; }
  .medium-padding-top-7 {
    padding-top: 2.94737rem; }
  .medium-padding-top-8 {
    padding-top: 3.36842rem; }
  .medium-padding-top-9 {
    padding-top: 3.78947rem; }
  .medium-padding-top-10 {
    padding-top: 4.21053rem; }
  .medium-padding-top-11 {
    padding-top: 4.63158rem; }
  .medium-padding-top-12 {
    padding-top: 5.05263rem; }
  .medium-padding-top-13 {
    padding-top: 5.47368rem; }
  .medium-padding-top-14 {
    padding-top: 5.89474rem; }
  .medium-padding-top-15 {
    padding-top: 6.31579rem; }
  .medium-padding-top-16 {
    padding-top: 6.73684rem; }
  .medium-padding-top-17 {
    padding-top: 7.15789rem; }
  .medium-padding-top-18 {
    padding-top: 7.57895rem; }
  .medium-padding-top-19 {
    padding-top: 8rem; }
  .medium-padding-top-20 {
    padding-top: 8.42105rem; }
  .medium-padding-top-21 {
    padding-top: 8.84211rem; }
  .medium-padding-top-22 {
    padding-top: 9.26316rem; }
  .medium-padding-top-23 {
    padding-top: 9.68421rem; }
  .medium-padding-top-24 {
    padding-top: 10.10526rem; }
  .medium-padding-top-25 {
    padding-top: 10.52632rem; }
  .medium-padding-top-26 {
    padding-top: 10.94737rem; }
  .medium-padding-top-27 {
    padding-top: 11.36842rem; }
  .medium-padding-top-28 {
    padding-top: 11.78947rem; }
  .medium-padding-top-29 {
    padding-top: 12.21053rem; }
  .medium-padding-top-30 {
    padding-top: 12.63158rem; }
  .medium-padding-top-31 {
    padding-top: 13.05263rem; }
  .medium-padding-top-32 {
    padding-top: 13.47368rem; }
  .medium-padding-top-33 {
    padding-top: 13.89474rem; }
  .medium-padding-top-34 {
    padding-top: 14.31579rem; }
  .medium-padding-top-35 {
    padding-top: 14.73684rem; }
  .medium-padding-top-36 {
    padding-top: 15.15789rem; }
  .medium-padding-top-37 {
    padding-top: 15.57895rem; }
  .medium-padding-top-38 {
    padding-top: 16rem; }
  .medium-padding-top-39 {
    padding-top: 16.42105rem; }
  .medium-padding-top-40 {
    padding-top: 16.84211rem; }
  .medium-padding-top-41 {
    padding-top: 17.26316rem; }
  .medium-padding-top-42 {
    padding-top: 17.68421rem; }
  .medium-padding-top-43 {
    padding-top: 18.10526rem; }
  .medium-padding-top-44 {
    padding-top: 18.52632rem; }
  .medium-padding-top-45 {
    padding-top: 18.94737rem; }
  .medium-padding-top-46 {
    padding-top: 19.36842rem; }
  .medium-padding-top-47 {
    padding-top: 19.78947rem; }
  .medium-padding-top-48 {
    padding-top: 20.21053rem; }
  .medium-padding-bottom-0 {
    padding-bottom: 0; }
  .medium-padding-bottom-1 {
    padding-bottom: 0.42105rem; }
  .medium-padding-bottom-2 {
    padding-bottom: 0.84211rem; }
  .medium-padding-bottom-3 {
    padding-bottom: 1.26316rem; }
  .medium-padding-bottom-4 {
    padding-bottom: 1.68421rem; }
  .medium-padding-bottom-5 {
    padding-bottom: 2.10526rem; }
  .medium-padding-bottom-6 {
    padding-bottom: 2.52632rem; }
  .medium-padding-bottom-7 {
    padding-bottom: 2.94737rem; }
  .medium-padding-bottom-8 {
    padding-bottom: 3.36842rem; }
  .medium-padding-bottom-9 {
    padding-bottom: 3.78947rem; }
  .medium-padding-bottom-10 {
    padding-bottom: 4.21053rem; }
  .medium-padding-bottom-11 {
    padding-bottom: 4.63158rem; }
  .medium-padding-bottom-12 {
    padding-bottom: 5.05263rem; }
  .medium-padding-bottom-13 {
    padding-bottom: 5.47368rem; }
  .medium-padding-bottom-14 {
    padding-bottom: 5.89474rem; }
  .medium-padding-bottom-15 {
    padding-bottom: 6.31579rem; }
  .medium-padding-bottom-16 {
    padding-bottom: 6.73684rem; }
  .medium-padding-bottom-17 {
    padding-bottom: 7.15789rem; }
  .medium-padding-bottom-18 {
    padding-bottom: 7.57895rem; }
  .medium-padding-bottom-19 {
    padding-bottom: 8rem; }
  .medium-padding-bottom-20 {
    padding-bottom: 8.42105rem; }
  .medium-padding-bottom-21 {
    padding-bottom: 8.84211rem; }
  .medium-padding-bottom-22 {
    padding-bottom: 9.26316rem; }
  .medium-padding-bottom-23 {
    padding-bottom: 9.68421rem; }
  .medium-padding-bottom-24 {
    padding-bottom: 10.10526rem; }
  .medium-padding-bottom-25 {
    padding-bottom: 10.52632rem; }
  .medium-padding-bottom-26 {
    padding-bottom: 10.94737rem; }
  .medium-padding-bottom-27 {
    padding-bottom: 11.36842rem; }
  .medium-padding-bottom-28 {
    padding-bottom: 11.78947rem; }
  .medium-padding-bottom-29 {
    padding-bottom: 12.21053rem; }
  .medium-padding-bottom-30 {
    padding-bottom: 12.63158rem; }
  .medium-padding-bottom-31 {
    padding-bottom: 13.05263rem; }
  .medium-padding-bottom-32 {
    padding-bottom: 13.47368rem; }
  .medium-padding-bottom-33 {
    padding-bottom: 13.89474rem; }
  .medium-padding-bottom-34 {
    padding-bottom: 14.31579rem; }
  .medium-padding-bottom-35 {
    padding-bottom: 14.73684rem; }
  .medium-padding-bottom-36 {
    padding-bottom: 15.15789rem; }
  .medium-padding-bottom-37 {
    padding-bottom: 15.57895rem; }
  .medium-padding-bottom-38 {
    padding-bottom: 16rem; }
  .medium-padding-bottom-39 {
    padding-bottom: 16.42105rem; }
  .medium-padding-bottom-40 {
    padding-bottom: 16.84211rem; }
  .medium-padding-bottom-41 {
    padding-bottom: 17.26316rem; }
  .medium-padding-bottom-42 {
    padding-bottom: 17.68421rem; }
  .medium-padding-bottom-43 {
    padding-bottom: 18.10526rem; }
  .medium-padding-bottom-44 {
    padding-bottom: 18.52632rem; }
  .medium-padding-bottom-45 {
    padding-bottom: 18.94737rem; }
  .medium-padding-bottom-46 {
    padding-bottom: 19.36842rem; }
  .medium-padding-bottom-47 {
    padding-bottom: 19.78947rem; }
  .medium-padding-bottom-48 {
    padding-bottom: 20.21053rem; }
  .medium-padding-right-0 {
    padding-right: 0; }
  .medium-padding-right-1 {
    padding-right: 0.42105rem; }
  .medium-padding-right-2 {
    padding-right: 0.84211rem; }
  .medium-padding-right-3 {
    padding-right: 1.26316rem; }
  .medium-padding-right-4 {
    padding-right: 1.68421rem; }
  .medium-padding-right-5 {
    padding-right: 2.10526rem; }
  .medium-padding-right-6 {
    padding-right: 2.52632rem; }
  .medium-padding-right-7 {
    padding-right: 2.94737rem; }
  .medium-padding-right-8 {
    padding-right: 3.36842rem; }
  .medium-padding-right-9 {
    padding-right: 3.78947rem; }
  .medium-padding-right-10 {
    padding-right: 4.21053rem; }
  .medium-padding-right-11 {
    padding-right: 4.63158rem; }
  .medium-padding-right-12 {
    padding-right: 5.05263rem; }
  .medium-padding-right-13 {
    padding-right: 5.47368rem; }
  .medium-padding-right-14 {
    padding-right: 5.89474rem; }
  .medium-padding-right-15 {
    padding-right: 6.31579rem; }
  .medium-padding-right-16 {
    padding-right: 6.73684rem; }
  .medium-padding-right-17 {
    padding-right: 7.15789rem; }
  .medium-padding-right-18 {
    padding-right: 7.57895rem; }
  .medium-padding-right-19 {
    padding-right: 8rem; }
  .medium-padding-right-20 {
    padding-right: 8.42105rem; }
  .medium-padding-right-21 {
    padding-right: 8.84211rem; }
  .medium-padding-right-22 {
    padding-right: 9.26316rem; }
  .medium-padding-right-23 {
    padding-right: 9.68421rem; }
  .medium-padding-right-24 {
    padding-right: 10.10526rem; }
  .medium-padding-right-25 {
    padding-right: 10.52632rem; }
  .medium-padding-right-26 {
    padding-right: 10.94737rem; }
  .medium-padding-right-27 {
    padding-right: 11.36842rem; }
  .medium-padding-right-28 {
    padding-right: 11.78947rem; }
  .medium-padding-right-29 {
    padding-right: 12.21053rem; }
  .medium-padding-right-30 {
    padding-right: 12.63158rem; }
  .medium-padding-right-31 {
    padding-right: 13.05263rem; }
  .medium-padding-right-32 {
    padding-right: 13.47368rem; }
  .medium-padding-right-33 {
    padding-right: 13.89474rem; }
  .medium-padding-right-34 {
    padding-right: 14.31579rem; }
  .medium-padding-right-35 {
    padding-right: 14.73684rem; }
  .medium-padding-right-36 {
    padding-right: 15.15789rem; }
  .medium-padding-right-37 {
    padding-right: 15.57895rem; }
  .medium-padding-right-38 {
    padding-right: 16rem; }
  .medium-padding-right-39 {
    padding-right: 16.42105rem; }
  .medium-padding-right-40 {
    padding-right: 16.84211rem; }
  .medium-padding-right-41 {
    padding-right: 17.26316rem; }
  .medium-padding-right-42 {
    padding-right: 17.68421rem; }
  .medium-padding-right-43 {
    padding-right: 18.10526rem; }
  .medium-padding-right-44 {
    padding-right: 18.52632rem; }
  .medium-padding-right-45 {
    padding-right: 18.94737rem; }
  .medium-padding-right-46 {
    padding-right: 19.36842rem; }
  .medium-padding-right-47 {
    padding-right: 19.78947rem; }
  .medium-padding-right-48 {
    padding-right: 20.21053rem; }
  .medium-padding-left-0 {
    padding-left: 0; }
  .medium-padding-left-1 {
    padding-left: 0.42105rem; }
  .medium-padding-left-2 {
    padding-left: 0.84211rem; }
  .medium-padding-left-3 {
    padding-left: 1.26316rem; }
  .medium-padding-left-4 {
    padding-left: 1.68421rem; }
  .medium-padding-left-5 {
    padding-left: 2.10526rem; }
  .medium-padding-left-6 {
    padding-left: 2.52632rem; }
  .medium-padding-left-7 {
    padding-left: 2.94737rem; }
  .medium-padding-left-8 {
    padding-left: 3.36842rem; }
  .medium-padding-left-9 {
    padding-left: 3.78947rem; }
  .medium-padding-left-10 {
    padding-left: 4.21053rem; }
  .medium-padding-left-11 {
    padding-left: 4.63158rem; }
  .medium-padding-left-12 {
    padding-left: 5.05263rem; }
  .medium-padding-left-13 {
    padding-left: 5.47368rem; }
  .medium-padding-left-14 {
    padding-left: 5.89474rem; }
  .medium-padding-left-15 {
    padding-left: 6.31579rem; }
  .medium-padding-left-16 {
    padding-left: 6.73684rem; }
  .medium-padding-left-17 {
    padding-left: 7.15789rem; }
  .medium-padding-left-18 {
    padding-left: 7.57895rem; }
  .medium-padding-left-19 {
    padding-left: 8rem; }
  .medium-padding-left-20 {
    padding-left: 8.42105rem; }
  .medium-padding-left-21 {
    padding-left: 8.84211rem; }
  .medium-padding-left-22 {
    padding-left: 9.26316rem; }
  .medium-padding-left-23 {
    padding-left: 9.68421rem; }
  .medium-padding-left-24 {
    padding-left: 10.10526rem; }
  .medium-padding-left-25 {
    padding-left: 10.52632rem; }
  .medium-padding-left-26 {
    padding-left: 10.94737rem; }
  .medium-padding-left-27 {
    padding-left: 11.36842rem; }
  .medium-padding-left-28 {
    padding-left: 11.78947rem; }
  .medium-padding-left-29 {
    padding-left: 12.21053rem; }
  .medium-padding-left-30 {
    padding-left: 12.63158rem; }
  .medium-padding-left-31 {
    padding-left: 13.05263rem; }
  .medium-padding-left-32 {
    padding-left: 13.47368rem; }
  .medium-padding-left-33 {
    padding-left: 13.89474rem; }
  .medium-padding-left-34 {
    padding-left: 14.31579rem; }
  .medium-padding-left-35 {
    padding-left: 14.73684rem; }
  .medium-padding-left-36 {
    padding-left: 15.15789rem; }
  .medium-padding-left-37 {
    padding-left: 15.57895rem; }
  .medium-padding-left-38 {
    padding-left: 16rem; }
  .medium-padding-left-39 {
    padding-left: 16.42105rem; }
  .medium-padding-left-40 {
    padding-left: 16.84211rem; }
  .medium-padding-left-41 {
    padding-left: 17.26316rem; }
  .medium-padding-left-42 {
    padding-left: 17.68421rem; }
  .medium-padding-left-43 {
    padding-left: 18.10526rem; }
  .medium-padding-left-44 {
    padding-left: 18.52632rem; }
  .medium-padding-left-45 {
    padding-left: 18.94737rem; }
  .medium-padding-left-46 {
    padding-left: 19.36842rem; }
  .medium-padding-left-47 {
    padding-left: 19.78947rem; }
  .medium-padding-left-48 {
    padding-left: 20.21053rem; }
  .medium-padding-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .medium-padding-1 {
    padding-top: 0.42105rem;
    padding-bottom: 0.42105rem; }
  .medium-padding-2 {
    padding-top: 0.84211rem;
    padding-bottom: 0.84211rem; }
  .medium-padding-3 {
    padding-top: 1.26316rem;
    padding-bottom: 1.26316rem; }
  .medium-padding-4 {
    padding-top: 1.68421rem;
    padding-bottom: 1.68421rem; }
  .medium-padding-5 {
    padding-top: 2.10526rem;
    padding-bottom: 2.10526rem; }
  .medium-padding-6 {
    padding-top: 2.52632rem;
    padding-bottom: 2.52632rem; }
  .medium-padding-7 {
    padding-top: 2.94737rem;
    padding-bottom: 2.94737rem; }
  .medium-padding-8 {
    padding-top: 3.36842rem;
    padding-bottom: 3.36842rem; }
  .medium-padding-9 {
    padding-top: 3.78947rem;
    padding-bottom: 3.78947rem; }
  .medium-padding-10 {
    padding-top: 4.21053rem;
    padding-bottom: 4.21053rem; }
  .medium-padding-11 {
    padding-top: 4.63158rem;
    padding-bottom: 4.63158rem; }
  .medium-padding-12 {
    padding-top: 5.05263rem;
    padding-bottom: 5.05263rem; }
  .medium-padding-13 {
    padding-top: 5.47368rem;
    padding-bottom: 5.47368rem; }
  .medium-padding-14 {
    padding-top: 5.89474rem;
    padding-bottom: 5.89474rem; }
  .medium-padding-15 {
    padding-top: 6.31579rem;
    padding-bottom: 6.31579rem; }
  .medium-padding-16 {
    padding-top: 6.73684rem;
    padding-bottom: 6.73684rem; }
  .medium-padding-17 {
    padding-top: 7.15789rem;
    padding-bottom: 7.15789rem; }
  .medium-padding-18 {
    padding-top: 7.57895rem;
    padding-bottom: 7.57895rem; }
  .medium-padding-19 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .medium-padding-20 {
    padding-top: 8.42105rem;
    padding-bottom: 8.42105rem; }
  .medium-padding-21 {
    padding-top: 8.84211rem;
    padding-bottom: 8.84211rem; }
  .medium-padding-22 {
    padding-top: 9.26316rem;
    padding-bottom: 9.26316rem; }
  .medium-padding-23 {
    padding-top: 9.68421rem;
    padding-bottom: 9.68421rem; }
  .medium-padding-24 {
    padding-top: 10.10526rem;
    padding-bottom: 10.10526rem; }
  .medium-padding-25 {
    padding-top: 10.52632rem;
    padding-bottom: 10.52632rem; }
  .medium-padding-26 {
    padding-top: 10.94737rem;
    padding-bottom: 10.94737rem; }
  .medium-padding-27 {
    padding-top: 11.36842rem;
    padding-bottom: 11.36842rem; }
  .medium-padding-28 {
    padding-top: 11.78947rem;
    padding-bottom: 11.78947rem; }
  .medium-padding-29 {
    padding-top: 12.21053rem;
    padding-bottom: 12.21053rem; }
  .medium-padding-30 {
    padding-top: 12.63158rem;
    padding-bottom: 12.63158rem; }
  .medium-padding-31 {
    padding-top: 13.05263rem;
    padding-bottom: 13.05263rem; }
  .medium-padding-32 {
    padding-top: 13.47368rem;
    padding-bottom: 13.47368rem; }
  .medium-padding-33 {
    padding-top: 13.89474rem;
    padding-bottom: 13.89474rem; }
  .medium-padding-34 {
    padding-top: 14.31579rem;
    padding-bottom: 14.31579rem; }
  .medium-padding-35 {
    padding-top: 14.73684rem;
    padding-bottom: 14.73684rem; }
  .medium-padding-36 {
    padding-top: 15.15789rem;
    padding-bottom: 15.15789rem; }
  .medium-padding-37 {
    padding-top: 15.57895rem;
    padding-bottom: 15.57895rem; }
  .medium-padding-38 {
    padding-top: 16rem;
    padding-bottom: 16rem; }
  .medium-padding-39 {
    padding-top: 16.42105rem;
    padding-bottom: 16.42105rem; }
  .medium-padding-40 {
    padding-top: 16.84211rem;
    padding-bottom: 16.84211rem; }
  .medium-padding-41 {
    padding-top: 17.26316rem;
    padding-bottom: 17.26316rem; }
  .medium-padding-42 {
    padding-top: 17.68421rem;
    padding-bottom: 17.68421rem; }
  .medium-padding-43 {
    padding-top: 18.10526rem;
    padding-bottom: 18.10526rem; }
  .medium-padding-44 {
    padding-top: 18.52632rem;
    padding-bottom: 18.52632rem; }
  .medium-padding-45 {
    padding-top: 18.94737rem;
    padding-bottom: 18.94737rem; }
  .medium-padding-46 {
    padding-top: 19.36842rem;
    padding-bottom: 19.36842rem; }
  .medium-padding-47 {
    padding-top: 19.78947rem;
    padding-bottom: 19.78947rem; }
  .medium-padding-48 {
    padding-top: 20.21053rem;
    padding-bottom: 20.21053rem; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  .large-margin-top-0 {
    margin-top: 0; }
  .large-margin-top-1 {
    margin-top: 0.42105rem; }
  .large-margin-top-2 {
    margin-top: 0.84211rem; }
  .large-margin-top-3 {
    margin-top: 1.26316rem; }
  .large-margin-top-4 {
    margin-top: 1.68421rem; }
  .large-margin-top-5 {
    margin-top: 2.10526rem; }
  .large-margin-top-6 {
    margin-top: 2.52632rem; }
  .large-margin-top-7 {
    margin-top: 2.94737rem; }
  .large-margin-top-8 {
    margin-top: 3.36842rem; }
  .large-margin-top-9 {
    margin-top: 3.78947rem; }
  .large-margin-top-10 {
    margin-top: 4.21053rem; }
  .large-margin-top-11 {
    margin-top: 4.63158rem; }
  .large-margin-top-12 {
    margin-top: 5.05263rem; }
  .large-margin-top-13 {
    margin-top: 5.47368rem; }
  .large-margin-top-14 {
    margin-top: 5.89474rem; }
  .large-margin-top-15 {
    margin-top: 6.31579rem; }
  .large-margin-top-16 {
    margin-top: 6.73684rem; }
  .large-margin-top-17 {
    margin-top: 7.15789rem; }
  .large-margin-top-18 {
    margin-top: 7.57895rem; }
  .large-margin-top-19 {
    margin-top: 8rem; }
  .large-margin-top-20 {
    margin-top: 8.42105rem; }
  .large-margin-top-21 {
    margin-top: 8.84211rem; }
  .large-margin-top-22 {
    margin-top: 9.26316rem; }
  .large-margin-top-23 {
    margin-top: 9.68421rem; }
  .large-margin-top-24 {
    margin-top: 10.10526rem; }
  .large-margin-top-25 {
    margin-top: 10.52632rem; }
  .large-margin-top-26 {
    margin-top: 10.94737rem; }
  .large-margin-top-27 {
    margin-top: 11.36842rem; }
  .large-margin-top-28 {
    margin-top: 11.78947rem; }
  .large-margin-top-29 {
    margin-top: 12.21053rem; }
  .large-margin-top-30 {
    margin-top: 12.63158rem; }
  .large-margin-top-31 {
    margin-top: 13.05263rem; }
  .large-margin-top-32 {
    margin-top: 13.47368rem; }
  .large-margin-top-33 {
    margin-top: 13.89474rem; }
  .large-margin-top-34 {
    margin-top: 14.31579rem; }
  .large-margin-top-35 {
    margin-top: 14.73684rem; }
  .large-margin-top-36 {
    margin-top: 15.15789rem; }
  .large-margin-top-37 {
    margin-top: 15.57895rem; }
  .large-margin-top-38 {
    margin-top: 16rem; }
  .large-margin-top-39 {
    margin-top: 16.42105rem; }
  .large-margin-top-40 {
    margin-top: 16.84211rem; }
  .large-margin-top-41 {
    margin-top: 17.26316rem; }
  .large-margin-top-42 {
    margin-top: 17.68421rem; }
  .large-margin-top-43 {
    margin-top: 18.10526rem; }
  .large-margin-top-44 {
    margin-top: 18.52632rem; }
  .large-margin-top-45 {
    margin-top: 18.94737rem; }
  .large-margin-top-46 {
    margin-top: 19.36842rem; }
  .large-margin-top-47 {
    margin-top: 19.78947rem; }
  .large-margin-top-48 {
    margin-top: 20.21053rem; }
  .large-margin-bottom-0 {
    margin-bottom: 0; }
  .large-margin-bottom-1 {
    margin-bottom: 0.42105rem; }
  .large-margin-bottom-2 {
    margin-bottom: 0.84211rem; }
  .large-margin-bottom-3 {
    margin-bottom: 1.26316rem; }
  .large-margin-bottom-4 {
    margin-bottom: 1.68421rem; }
  .large-margin-bottom-5 {
    margin-bottom: 2.10526rem; }
  .large-margin-bottom-6 {
    margin-bottom: 2.52632rem; }
  .large-margin-bottom-7 {
    margin-bottom: 2.94737rem; }
  .large-margin-bottom-8 {
    margin-bottom: 3.36842rem; }
  .large-margin-bottom-9 {
    margin-bottom: 3.78947rem; }
  .large-margin-bottom-10 {
    margin-bottom: 4.21053rem; }
  .large-margin-bottom-11 {
    margin-bottom: 4.63158rem; }
  .large-margin-bottom-12 {
    margin-bottom: 5.05263rem; }
  .large-margin-bottom-13 {
    margin-bottom: 5.47368rem; }
  .large-margin-bottom-14 {
    margin-bottom: 5.89474rem; }
  .large-margin-bottom-15 {
    margin-bottom: 6.31579rem; }
  .large-margin-bottom-16 {
    margin-bottom: 6.73684rem; }
  .large-margin-bottom-17 {
    margin-bottom: 7.15789rem; }
  .large-margin-bottom-18 {
    margin-bottom: 7.57895rem; }
  .large-margin-bottom-19 {
    margin-bottom: 8rem; }
  .large-margin-bottom-20 {
    margin-bottom: 8.42105rem; }
  .large-margin-bottom-21 {
    margin-bottom: 8.84211rem; }
  .large-margin-bottom-22 {
    margin-bottom: 9.26316rem; }
  .large-margin-bottom-23 {
    margin-bottom: 9.68421rem; }
  .large-margin-bottom-24 {
    margin-bottom: 10.10526rem; }
  .large-margin-bottom-25 {
    margin-bottom: 10.52632rem; }
  .large-margin-bottom-26 {
    margin-bottom: 10.94737rem; }
  .large-margin-bottom-27 {
    margin-bottom: 11.36842rem; }
  .large-margin-bottom-28 {
    margin-bottom: 11.78947rem; }
  .large-margin-bottom-29 {
    margin-bottom: 12.21053rem; }
  .large-margin-bottom-30 {
    margin-bottom: 12.63158rem; }
  .large-margin-bottom-31 {
    margin-bottom: 13.05263rem; }
  .large-margin-bottom-32 {
    margin-bottom: 13.47368rem; }
  .large-margin-bottom-33 {
    margin-bottom: 13.89474rem; }
  .large-margin-bottom-34 {
    margin-bottom: 14.31579rem; }
  .large-margin-bottom-35 {
    margin-bottom: 14.73684rem; }
  .large-margin-bottom-36 {
    margin-bottom: 15.15789rem; }
  .large-margin-bottom-37 {
    margin-bottom: 15.57895rem; }
  .large-margin-bottom-38 {
    margin-bottom: 16rem; }
  .large-margin-bottom-39 {
    margin-bottom: 16.42105rem; }
  .large-margin-bottom-40 {
    margin-bottom: 16.84211rem; }
  .large-margin-bottom-41 {
    margin-bottom: 17.26316rem; }
  .large-margin-bottom-42 {
    margin-bottom: 17.68421rem; }
  .large-margin-bottom-43 {
    margin-bottom: 18.10526rem; }
  .large-margin-bottom-44 {
    margin-bottom: 18.52632rem; }
  .large-margin-bottom-45 {
    margin-bottom: 18.94737rem; }
  .large-margin-bottom-46 {
    margin-bottom: 19.36842rem; }
  .large-margin-bottom-47 {
    margin-bottom: 19.78947rem; }
  .large-margin-bottom-48 {
    margin-bottom: 20.21053rem; }
  .large-margin-right-0 {
    margin-right: 0; }
  .large-margin-right-1 {
    margin-right: 0.42105rem; }
  .large-margin-right-2 {
    margin-right: 0.84211rem; }
  .large-margin-right-3 {
    margin-right: 1.26316rem; }
  .large-margin-right-4 {
    margin-right: 1.68421rem; }
  .large-margin-right-5 {
    margin-right: 2.10526rem; }
  .large-margin-right-6 {
    margin-right: 2.52632rem; }
  .large-margin-right-7 {
    margin-right: 2.94737rem; }
  .large-margin-right-8 {
    margin-right: 3.36842rem; }
  .large-margin-right-9 {
    margin-right: 3.78947rem; }
  .large-margin-right-10 {
    margin-right: 4.21053rem; }
  .large-margin-right-11 {
    margin-right: 4.63158rem; }
  .large-margin-right-12 {
    margin-right: 5.05263rem; }
  .large-margin-right-13 {
    margin-right: 5.47368rem; }
  .large-margin-right-14 {
    margin-right: 5.89474rem; }
  .large-margin-right-15 {
    margin-right: 6.31579rem; }
  .large-margin-right-16 {
    margin-right: 6.73684rem; }
  .large-margin-right-17 {
    margin-right: 7.15789rem; }
  .large-margin-right-18 {
    margin-right: 7.57895rem; }
  .large-margin-right-19 {
    margin-right: 8rem; }
  .large-margin-right-20 {
    margin-right: 8.42105rem; }
  .large-margin-right-21 {
    margin-right: 8.84211rem; }
  .large-margin-right-22 {
    margin-right: 9.26316rem; }
  .large-margin-right-23 {
    margin-right: 9.68421rem; }
  .large-margin-right-24 {
    margin-right: 10.10526rem; }
  .large-margin-right-25 {
    margin-right: 10.52632rem; }
  .large-margin-right-26 {
    margin-right: 10.94737rem; }
  .large-margin-right-27 {
    margin-right: 11.36842rem; }
  .large-margin-right-28 {
    margin-right: 11.78947rem; }
  .large-margin-right-29 {
    margin-right: 12.21053rem; }
  .large-margin-right-30 {
    margin-right: 12.63158rem; }
  .large-margin-right-31 {
    margin-right: 13.05263rem; }
  .large-margin-right-32 {
    margin-right: 13.47368rem; }
  .large-margin-right-33 {
    margin-right: 13.89474rem; }
  .large-margin-right-34 {
    margin-right: 14.31579rem; }
  .large-margin-right-35 {
    margin-right: 14.73684rem; }
  .large-margin-right-36 {
    margin-right: 15.15789rem; }
  .large-margin-right-37 {
    margin-right: 15.57895rem; }
  .large-margin-right-38 {
    margin-right: 16rem; }
  .large-margin-right-39 {
    margin-right: 16.42105rem; }
  .large-margin-right-40 {
    margin-right: 16.84211rem; }
  .large-margin-right-41 {
    margin-right: 17.26316rem; }
  .large-margin-right-42 {
    margin-right: 17.68421rem; }
  .large-margin-right-43 {
    margin-right: 18.10526rem; }
  .large-margin-right-44 {
    margin-right: 18.52632rem; }
  .large-margin-right-45 {
    margin-right: 18.94737rem; }
  .large-margin-right-46 {
    margin-right: 19.36842rem; }
  .large-margin-right-47 {
    margin-right: 19.78947rem; }
  .large-margin-right-48 {
    margin-right: 20.21053rem; }
  .large-margin-left-0 {
    margin-left: 0; }
  .large-margin-left-1 {
    margin-left: 0.42105rem; }
  .large-margin-left-2 {
    margin-left: 0.84211rem; }
  .large-margin-left-3 {
    margin-left: 1.26316rem; }
  .large-margin-left-4 {
    margin-left: 1.68421rem; }
  .large-margin-left-5 {
    margin-left: 2.10526rem; }
  .large-margin-left-6 {
    margin-left: 2.52632rem; }
  .large-margin-left-7 {
    margin-left: 2.94737rem; }
  .large-margin-left-8 {
    margin-left: 3.36842rem; }
  .large-margin-left-9 {
    margin-left: 3.78947rem; }
  .large-margin-left-10 {
    margin-left: 4.21053rem; }
  .large-margin-left-11 {
    margin-left: 4.63158rem; }
  .large-margin-left-12 {
    margin-left: 5.05263rem; }
  .large-margin-left-13 {
    margin-left: 5.47368rem; }
  .large-margin-left-14 {
    margin-left: 5.89474rem; }
  .large-margin-left-15 {
    margin-left: 6.31579rem; }
  .large-margin-left-16 {
    margin-left: 6.73684rem; }
  .large-margin-left-17 {
    margin-left: 7.15789rem; }
  .large-margin-left-18 {
    margin-left: 7.57895rem; }
  .large-margin-left-19 {
    margin-left: 8rem; }
  .large-margin-left-20 {
    margin-left: 8.42105rem; }
  .large-margin-left-21 {
    margin-left: 8.84211rem; }
  .large-margin-left-22 {
    margin-left: 9.26316rem; }
  .large-margin-left-23 {
    margin-left: 9.68421rem; }
  .large-margin-left-24 {
    margin-left: 10.10526rem; }
  .large-margin-left-25 {
    margin-left: 10.52632rem; }
  .large-margin-left-26 {
    margin-left: 10.94737rem; }
  .large-margin-left-27 {
    margin-left: 11.36842rem; }
  .large-margin-left-28 {
    margin-left: 11.78947rem; }
  .large-margin-left-29 {
    margin-left: 12.21053rem; }
  .large-margin-left-30 {
    margin-left: 12.63158rem; }
  .large-margin-left-31 {
    margin-left: 13.05263rem; }
  .large-margin-left-32 {
    margin-left: 13.47368rem; }
  .large-margin-left-33 {
    margin-left: 13.89474rem; }
  .large-margin-left-34 {
    margin-left: 14.31579rem; }
  .large-margin-left-35 {
    margin-left: 14.73684rem; }
  .large-margin-left-36 {
    margin-left: 15.15789rem; }
  .large-margin-left-37 {
    margin-left: 15.57895rem; }
  .large-margin-left-38 {
    margin-left: 16rem; }
  .large-margin-left-39 {
    margin-left: 16.42105rem; }
  .large-margin-left-40 {
    margin-left: 16.84211rem; }
  .large-margin-left-41 {
    margin-left: 17.26316rem; }
  .large-margin-left-42 {
    margin-left: 17.68421rem; }
  .large-margin-left-43 {
    margin-left: 18.10526rem; }
  .large-margin-left-44 {
    margin-left: 18.52632rem; }
  .large-margin-left-45 {
    margin-left: 18.94737rem; }
  .large-margin-left-46 {
    margin-left: 19.36842rem; }
  .large-margin-left-47 {
    margin-left: 19.78947rem; }
  .large-margin-left-48 {
    margin-left: 20.21053rem; }
  .large-margin-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .large-margin-1 {
    margin-top: 0.42105rem;
    margin-bottom: 0.42105rem; }
  .large-margin-2 {
    margin-top: 0.84211rem;
    margin-bottom: 0.84211rem; }
  .large-margin-3 {
    margin-top: 1.26316rem;
    margin-bottom: 1.26316rem; }
  .large-margin-4 {
    margin-top: 1.68421rem;
    margin-bottom: 1.68421rem; }
  .large-margin-5 {
    margin-top: 2.10526rem;
    margin-bottom: 2.10526rem; }
  .large-margin-6 {
    margin-top: 2.52632rem;
    margin-bottom: 2.52632rem; }
  .large-margin-7 {
    margin-top: 2.94737rem;
    margin-bottom: 2.94737rem; }
  .large-margin-8 {
    margin-top: 3.36842rem;
    margin-bottom: 3.36842rem; }
  .large-margin-9 {
    margin-top: 3.78947rem;
    margin-bottom: 3.78947rem; }
  .large-margin-10 {
    margin-top: 4.21053rem;
    margin-bottom: 4.21053rem; }
  .large-margin-11 {
    margin-top: 4.63158rem;
    margin-bottom: 4.63158rem; }
  .large-margin-12 {
    margin-top: 5.05263rem;
    margin-bottom: 5.05263rem; }
  .large-margin-13 {
    margin-top: 5.47368rem;
    margin-bottom: 5.47368rem; }
  .large-margin-14 {
    margin-top: 5.89474rem;
    margin-bottom: 5.89474rem; }
  .large-margin-15 {
    margin-top: 6.31579rem;
    margin-bottom: 6.31579rem; }
  .large-margin-16 {
    margin-top: 6.73684rem;
    margin-bottom: 6.73684rem; }
  .large-margin-17 {
    margin-top: 7.15789rem;
    margin-bottom: 7.15789rem; }
  .large-margin-18 {
    margin-top: 7.57895rem;
    margin-bottom: 7.57895rem; }
  .large-margin-19 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .large-margin-20 {
    margin-top: 8.42105rem;
    margin-bottom: 8.42105rem; }
  .large-margin-21 {
    margin-top: 8.84211rem;
    margin-bottom: 8.84211rem; }
  .large-margin-22 {
    margin-top: 9.26316rem;
    margin-bottom: 9.26316rem; }
  .large-margin-23 {
    margin-top: 9.68421rem;
    margin-bottom: 9.68421rem; }
  .large-margin-24 {
    margin-top: 10.10526rem;
    margin-bottom: 10.10526rem; }
  .large-margin-25 {
    margin-top: 10.52632rem;
    margin-bottom: 10.52632rem; }
  .large-margin-26 {
    margin-top: 10.94737rem;
    margin-bottom: 10.94737rem; }
  .large-margin-27 {
    margin-top: 11.36842rem;
    margin-bottom: 11.36842rem; }
  .large-margin-28 {
    margin-top: 11.78947rem;
    margin-bottom: 11.78947rem; }
  .large-margin-29 {
    margin-top: 12.21053rem;
    margin-bottom: 12.21053rem; }
  .large-margin-30 {
    margin-top: 12.63158rem;
    margin-bottom: 12.63158rem; }
  .large-margin-31 {
    margin-top: 13.05263rem;
    margin-bottom: 13.05263rem; }
  .large-margin-32 {
    margin-top: 13.47368rem;
    margin-bottom: 13.47368rem; }
  .large-margin-33 {
    margin-top: 13.89474rem;
    margin-bottom: 13.89474rem; }
  .large-margin-34 {
    margin-top: 14.31579rem;
    margin-bottom: 14.31579rem; }
  .large-margin-35 {
    margin-top: 14.73684rem;
    margin-bottom: 14.73684rem; }
  .large-margin-36 {
    margin-top: 15.15789rem;
    margin-bottom: 15.15789rem; }
  .large-margin-37 {
    margin-top: 15.57895rem;
    margin-bottom: 15.57895rem; }
  .large-margin-38 {
    margin-top: 16rem;
    margin-bottom: 16rem; }
  .large-margin-39 {
    margin-top: 16.42105rem;
    margin-bottom: 16.42105rem; }
  .large-margin-40 {
    margin-top: 16.84211rem;
    margin-bottom: 16.84211rem; }
  .large-margin-41 {
    margin-top: 17.26316rem;
    margin-bottom: 17.26316rem; }
  .large-margin-42 {
    margin-top: 17.68421rem;
    margin-bottom: 17.68421rem; }
  .large-margin-43 {
    margin-top: 18.10526rem;
    margin-bottom: 18.10526rem; }
  .large-margin-44 {
    margin-top: 18.52632rem;
    margin-bottom: 18.52632rem; }
  .large-margin-45 {
    margin-top: 18.94737rem;
    margin-bottom: 18.94737rem; }
  .large-margin-46 {
    margin-top: 19.36842rem;
    margin-bottom: 19.36842rem; }
  .large-margin-47 {
    margin-top: 19.78947rem;
    margin-bottom: 19.78947rem; }
  .large-margin-48 {
    margin-top: 20.21053rem;
    margin-bottom: 20.21053rem; }
  .large-padding-top-0 {
    padding-top: 0; }
  .large-padding-top-1 {
    padding-top: 0.42105rem; }
  .large-padding-top-2 {
    padding-top: 0.84211rem; }
  .large-padding-top-3 {
    padding-top: 1.26316rem; }
  .large-padding-top-4 {
    padding-top: 1.68421rem; }
  .large-padding-top-5 {
    padding-top: 2.10526rem; }
  .large-padding-top-6 {
    padding-top: 2.52632rem; }
  .large-padding-top-7 {
    padding-top: 2.94737rem; }
  .large-padding-top-8 {
    padding-top: 3.36842rem; }
  .large-padding-top-9 {
    padding-top: 3.78947rem; }
  .large-padding-top-10 {
    padding-top: 4.21053rem; }
  .large-padding-top-11 {
    padding-top: 4.63158rem; }
  .large-padding-top-12 {
    padding-top: 5.05263rem; }
  .large-padding-top-13 {
    padding-top: 5.47368rem; }
  .large-padding-top-14 {
    padding-top: 5.89474rem; }
  .large-padding-top-15 {
    padding-top: 6.31579rem; }
  .large-padding-top-16 {
    padding-top: 6.73684rem; }
  .large-padding-top-17 {
    padding-top: 7.15789rem; }
  .large-padding-top-18 {
    padding-top: 7.57895rem; }
  .large-padding-top-19 {
    padding-top: 8rem; }
  .large-padding-top-20 {
    padding-top: 8.42105rem; }
  .large-padding-top-21 {
    padding-top: 8.84211rem; }
  .large-padding-top-22 {
    padding-top: 9.26316rem; }
  .large-padding-top-23 {
    padding-top: 9.68421rem; }
  .large-padding-top-24 {
    padding-top: 10.10526rem; }
  .large-padding-top-25 {
    padding-top: 10.52632rem; }
  .large-padding-top-26 {
    padding-top: 10.94737rem; }
  .large-padding-top-27 {
    padding-top: 11.36842rem; }
  .large-padding-top-28 {
    padding-top: 11.78947rem; }
  .large-padding-top-29 {
    padding-top: 12.21053rem; }
  .large-padding-top-30 {
    padding-top: 12.63158rem; }
  .large-padding-top-31 {
    padding-top: 13.05263rem; }
  .large-padding-top-32 {
    padding-top: 13.47368rem; }
  .large-padding-top-33 {
    padding-top: 13.89474rem; }
  .large-padding-top-34 {
    padding-top: 14.31579rem; }
  .large-padding-top-35 {
    padding-top: 14.73684rem; }
  .large-padding-top-36 {
    padding-top: 15.15789rem; }
  .large-padding-top-37 {
    padding-top: 15.57895rem; }
  .large-padding-top-38 {
    padding-top: 16rem; }
  .large-padding-top-39 {
    padding-top: 16.42105rem; }
  .large-padding-top-40 {
    padding-top: 16.84211rem; }
  .large-padding-top-41 {
    padding-top: 17.26316rem; }
  .large-padding-top-42 {
    padding-top: 17.68421rem; }
  .large-padding-top-43 {
    padding-top: 18.10526rem; }
  .large-padding-top-44 {
    padding-top: 18.52632rem; }
  .large-padding-top-45 {
    padding-top: 18.94737rem; }
  .large-padding-top-46 {
    padding-top: 19.36842rem; }
  .large-padding-top-47 {
    padding-top: 19.78947rem; }
  .large-padding-top-48 {
    padding-top: 20.21053rem; }
  .large-padding-bottom-0 {
    padding-bottom: 0; }
  .large-padding-bottom-1 {
    padding-bottom: 0.42105rem; }
  .large-padding-bottom-2 {
    padding-bottom: 0.84211rem; }
  .large-padding-bottom-3 {
    padding-bottom: 1.26316rem; }
  .large-padding-bottom-4 {
    padding-bottom: 1.68421rem; }
  .large-padding-bottom-5 {
    padding-bottom: 2.10526rem; }
  .large-padding-bottom-6 {
    padding-bottom: 2.52632rem; }
  .large-padding-bottom-7 {
    padding-bottom: 2.94737rem; }
  .large-padding-bottom-8 {
    padding-bottom: 3.36842rem; }
  .large-padding-bottom-9 {
    padding-bottom: 3.78947rem; }
  .large-padding-bottom-10 {
    padding-bottom: 4.21053rem; }
  .large-padding-bottom-11 {
    padding-bottom: 4.63158rem; }
  .large-padding-bottom-12 {
    padding-bottom: 5.05263rem; }
  .large-padding-bottom-13 {
    padding-bottom: 5.47368rem; }
  .large-padding-bottom-14 {
    padding-bottom: 5.89474rem; }
  .large-padding-bottom-15 {
    padding-bottom: 6.31579rem; }
  .large-padding-bottom-16 {
    padding-bottom: 6.73684rem; }
  .large-padding-bottom-17 {
    padding-bottom: 7.15789rem; }
  .large-padding-bottom-18 {
    padding-bottom: 7.57895rem; }
  .large-padding-bottom-19 {
    padding-bottom: 8rem; }
  .large-padding-bottom-20 {
    padding-bottom: 8.42105rem; }
  .large-padding-bottom-21 {
    padding-bottom: 8.84211rem; }
  .large-padding-bottom-22 {
    padding-bottom: 9.26316rem; }
  .large-padding-bottom-23 {
    padding-bottom: 9.68421rem; }
  .large-padding-bottom-24 {
    padding-bottom: 10.10526rem; }
  .large-padding-bottom-25 {
    padding-bottom: 10.52632rem; }
  .large-padding-bottom-26 {
    padding-bottom: 10.94737rem; }
  .large-padding-bottom-27 {
    padding-bottom: 11.36842rem; }
  .large-padding-bottom-28 {
    padding-bottom: 11.78947rem; }
  .large-padding-bottom-29 {
    padding-bottom: 12.21053rem; }
  .large-padding-bottom-30 {
    padding-bottom: 12.63158rem; }
  .large-padding-bottom-31 {
    padding-bottom: 13.05263rem; }
  .large-padding-bottom-32 {
    padding-bottom: 13.47368rem; }
  .large-padding-bottom-33 {
    padding-bottom: 13.89474rem; }
  .large-padding-bottom-34 {
    padding-bottom: 14.31579rem; }
  .large-padding-bottom-35 {
    padding-bottom: 14.73684rem; }
  .large-padding-bottom-36 {
    padding-bottom: 15.15789rem; }
  .large-padding-bottom-37 {
    padding-bottom: 15.57895rem; }
  .large-padding-bottom-38 {
    padding-bottom: 16rem; }
  .large-padding-bottom-39 {
    padding-bottom: 16.42105rem; }
  .large-padding-bottom-40 {
    padding-bottom: 16.84211rem; }
  .large-padding-bottom-41 {
    padding-bottom: 17.26316rem; }
  .large-padding-bottom-42 {
    padding-bottom: 17.68421rem; }
  .large-padding-bottom-43 {
    padding-bottom: 18.10526rem; }
  .large-padding-bottom-44 {
    padding-bottom: 18.52632rem; }
  .large-padding-bottom-45 {
    padding-bottom: 18.94737rem; }
  .large-padding-bottom-46 {
    padding-bottom: 19.36842rem; }
  .large-padding-bottom-47 {
    padding-bottom: 19.78947rem; }
  .large-padding-bottom-48 {
    padding-bottom: 20.21053rem; }
  .large-padding-right-0 {
    padding-right: 0; }
  .large-padding-right-1 {
    padding-right: 0.42105rem; }
  .large-padding-right-2 {
    padding-right: 0.84211rem; }
  .large-padding-right-3 {
    padding-right: 1.26316rem; }
  .large-padding-right-4 {
    padding-right: 1.68421rem; }
  .large-padding-right-5 {
    padding-right: 2.10526rem; }
  .large-padding-right-6 {
    padding-right: 2.52632rem; }
  .large-padding-right-7 {
    padding-right: 2.94737rem; }
  .large-padding-right-8 {
    padding-right: 3.36842rem; }
  .large-padding-right-9 {
    padding-right: 3.78947rem; }
  .large-padding-right-10 {
    padding-right: 4.21053rem; }
  .large-padding-right-11 {
    padding-right: 4.63158rem; }
  .large-padding-right-12 {
    padding-right: 5.05263rem; }
  .large-padding-right-13 {
    padding-right: 5.47368rem; }
  .large-padding-right-14 {
    padding-right: 5.89474rem; }
  .large-padding-right-15 {
    padding-right: 6.31579rem; }
  .large-padding-right-16 {
    padding-right: 6.73684rem; }
  .large-padding-right-17 {
    padding-right: 7.15789rem; }
  .large-padding-right-18 {
    padding-right: 7.57895rem; }
  .large-padding-right-19 {
    padding-right: 8rem; }
  .large-padding-right-20 {
    padding-right: 8.42105rem; }
  .large-padding-right-21 {
    padding-right: 8.84211rem; }
  .large-padding-right-22 {
    padding-right: 9.26316rem; }
  .large-padding-right-23 {
    padding-right: 9.68421rem; }
  .large-padding-right-24 {
    padding-right: 10.10526rem; }
  .large-padding-right-25 {
    padding-right: 10.52632rem; }
  .large-padding-right-26 {
    padding-right: 10.94737rem; }
  .large-padding-right-27 {
    padding-right: 11.36842rem; }
  .large-padding-right-28 {
    padding-right: 11.78947rem; }
  .large-padding-right-29 {
    padding-right: 12.21053rem; }
  .large-padding-right-30 {
    padding-right: 12.63158rem; }
  .large-padding-right-31 {
    padding-right: 13.05263rem; }
  .large-padding-right-32 {
    padding-right: 13.47368rem; }
  .large-padding-right-33 {
    padding-right: 13.89474rem; }
  .large-padding-right-34 {
    padding-right: 14.31579rem; }
  .large-padding-right-35 {
    padding-right: 14.73684rem; }
  .large-padding-right-36 {
    padding-right: 15.15789rem; }
  .large-padding-right-37 {
    padding-right: 15.57895rem; }
  .large-padding-right-38 {
    padding-right: 16rem; }
  .large-padding-right-39 {
    padding-right: 16.42105rem; }
  .large-padding-right-40 {
    padding-right: 16.84211rem; }
  .large-padding-right-41 {
    padding-right: 17.26316rem; }
  .large-padding-right-42 {
    padding-right: 17.68421rem; }
  .large-padding-right-43 {
    padding-right: 18.10526rem; }
  .large-padding-right-44 {
    padding-right: 18.52632rem; }
  .large-padding-right-45 {
    padding-right: 18.94737rem; }
  .large-padding-right-46 {
    padding-right: 19.36842rem; }
  .large-padding-right-47 {
    padding-right: 19.78947rem; }
  .large-padding-right-48 {
    padding-right: 20.21053rem; }
  .large-padding-left-0 {
    padding-left: 0; }
  .large-padding-left-1 {
    padding-left: 0.42105rem; }
  .large-padding-left-2 {
    padding-left: 0.84211rem; }
  .large-padding-left-3 {
    padding-left: 1.26316rem; }
  .large-padding-left-4 {
    padding-left: 1.68421rem; }
  .large-padding-left-5 {
    padding-left: 2.10526rem; }
  .large-padding-left-6 {
    padding-left: 2.52632rem; }
  .large-padding-left-7 {
    padding-left: 2.94737rem; }
  .large-padding-left-8 {
    padding-left: 3.36842rem; }
  .large-padding-left-9 {
    padding-left: 3.78947rem; }
  .large-padding-left-10 {
    padding-left: 4.21053rem; }
  .large-padding-left-11 {
    padding-left: 4.63158rem; }
  .large-padding-left-12 {
    padding-left: 5.05263rem; }
  .large-padding-left-13 {
    padding-left: 5.47368rem; }
  .large-padding-left-14 {
    padding-left: 5.89474rem; }
  .large-padding-left-15 {
    padding-left: 6.31579rem; }
  .large-padding-left-16 {
    padding-left: 6.73684rem; }
  .large-padding-left-17 {
    padding-left: 7.15789rem; }
  .large-padding-left-18 {
    padding-left: 7.57895rem; }
  .large-padding-left-19 {
    padding-left: 8rem; }
  .large-padding-left-20 {
    padding-left: 8.42105rem; }
  .large-padding-left-21 {
    padding-left: 8.84211rem; }
  .large-padding-left-22 {
    padding-left: 9.26316rem; }
  .large-padding-left-23 {
    padding-left: 9.68421rem; }
  .large-padding-left-24 {
    padding-left: 10.10526rem; }
  .large-padding-left-25 {
    padding-left: 10.52632rem; }
  .large-padding-left-26 {
    padding-left: 10.94737rem; }
  .large-padding-left-27 {
    padding-left: 11.36842rem; }
  .large-padding-left-28 {
    padding-left: 11.78947rem; }
  .large-padding-left-29 {
    padding-left: 12.21053rem; }
  .large-padding-left-30 {
    padding-left: 12.63158rem; }
  .large-padding-left-31 {
    padding-left: 13.05263rem; }
  .large-padding-left-32 {
    padding-left: 13.47368rem; }
  .large-padding-left-33 {
    padding-left: 13.89474rem; }
  .large-padding-left-34 {
    padding-left: 14.31579rem; }
  .large-padding-left-35 {
    padding-left: 14.73684rem; }
  .large-padding-left-36 {
    padding-left: 15.15789rem; }
  .large-padding-left-37 {
    padding-left: 15.57895rem; }
  .large-padding-left-38 {
    padding-left: 16rem; }
  .large-padding-left-39 {
    padding-left: 16.42105rem; }
  .large-padding-left-40 {
    padding-left: 16.84211rem; }
  .large-padding-left-41 {
    padding-left: 17.26316rem; }
  .large-padding-left-42 {
    padding-left: 17.68421rem; }
  .large-padding-left-43 {
    padding-left: 18.10526rem; }
  .large-padding-left-44 {
    padding-left: 18.52632rem; }
  .large-padding-left-45 {
    padding-left: 18.94737rem; }
  .large-padding-left-46 {
    padding-left: 19.36842rem; }
  .large-padding-left-47 {
    padding-left: 19.78947rem; }
  .large-padding-left-48 {
    padding-left: 20.21053rem; }
  .large-padding-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .large-padding-1 {
    padding-top: 0.42105rem;
    padding-bottom: 0.42105rem; }
  .large-padding-2 {
    padding-top: 0.84211rem;
    padding-bottom: 0.84211rem; }
  .large-padding-3 {
    padding-top: 1.26316rem;
    padding-bottom: 1.26316rem; }
  .large-padding-4 {
    padding-top: 1.68421rem;
    padding-bottom: 1.68421rem; }
  .large-padding-5 {
    padding-top: 2.10526rem;
    padding-bottom: 2.10526rem; }
  .large-padding-6 {
    padding-top: 2.52632rem;
    padding-bottom: 2.52632rem; }
  .large-padding-7 {
    padding-top: 2.94737rem;
    padding-bottom: 2.94737rem; }
  .large-padding-8 {
    padding-top: 3.36842rem;
    padding-bottom: 3.36842rem; }
  .large-padding-9 {
    padding-top: 3.78947rem;
    padding-bottom: 3.78947rem; }
  .large-padding-10 {
    padding-top: 4.21053rem;
    padding-bottom: 4.21053rem; }
  .large-padding-11 {
    padding-top: 4.63158rem;
    padding-bottom: 4.63158rem; }
  .large-padding-12 {
    padding-top: 5.05263rem;
    padding-bottom: 5.05263rem; }
  .large-padding-13 {
    padding-top: 5.47368rem;
    padding-bottom: 5.47368rem; }
  .large-padding-14 {
    padding-top: 5.89474rem;
    padding-bottom: 5.89474rem; }
  .large-padding-15 {
    padding-top: 6.31579rem;
    padding-bottom: 6.31579rem; }
  .large-padding-16 {
    padding-top: 6.73684rem;
    padding-bottom: 6.73684rem; }
  .large-padding-17 {
    padding-top: 7.15789rem;
    padding-bottom: 7.15789rem; }
  .large-padding-18 {
    padding-top: 7.57895rem;
    padding-bottom: 7.57895rem; }
  .large-padding-19 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .large-padding-20 {
    padding-top: 8.42105rem;
    padding-bottom: 8.42105rem; }
  .large-padding-21 {
    padding-top: 8.84211rem;
    padding-bottom: 8.84211rem; }
  .large-padding-22 {
    padding-top: 9.26316rem;
    padding-bottom: 9.26316rem; }
  .large-padding-23 {
    padding-top: 9.68421rem;
    padding-bottom: 9.68421rem; }
  .large-padding-24 {
    padding-top: 10.10526rem;
    padding-bottom: 10.10526rem; }
  .large-padding-25 {
    padding-top: 10.52632rem;
    padding-bottom: 10.52632rem; }
  .large-padding-26 {
    padding-top: 10.94737rem;
    padding-bottom: 10.94737rem; }
  .large-padding-27 {
    padding-top: 11.36842rem;
    padding-bottom: 11.36842rem; }
  .large-padding-28 {
    padding-top: 11.78947rem;
    padding-bottom: 11.78947rem; }
  .large-padding-29 {
    padding-top: 12.21053rem;
    padding-bottom: 12.21053rem; }
  .large-padding-30 {
    padding-top: 12.63158rem;
    padding-bottom: 12.63158rem; }
  .large-padding-31 {
    padding-top: 13.05263rem;
    padding-bottom: 13.05263rem; }
  .large-padding-32 {
    padding-top: 13.47368rem;
    padding-bottom: 13.47368rem; }
  .large-padding-33 {
    padding-top: 13.89474rem;
    padding-bottom: 13.89474rem; }
  .large-padding-34 {
    padding-top: 14.31579rem;
    padding-bottom: 14.31579rem; }
  .large-padding-35 {
    padding-top: 14.73684rem;
    padding-bottom: 14.73684rem; }
  .large-padding-36 {
    padding-top: 15.15789rem;
    padding-bottom: 15.15789rem; }
  .large-padding-37 {
    padding-top: 15.57895rem;
    padding-bottom: 15.57895rem; }
  .large-padding-38 {
    padding-top: 16rem;
    padding-bottom: 16rem; }
  .large-padding-39 {
    padding-top: 16.42105rem;
    padding-bottom: 16.42105rem; }
  .large-padding-40 {
    padding-top: 16.84211rem;
    padding-bottom: 16.84211rem; }
  .large-padding-41 {
    padding-top: 17.26316rem;
    padding-bottom: 17.26316rem; }
  .large-padding-42 {
    padding-top: 17.68421rem;
    padding-bottom: 17.68421rem; }
  .large-padding-43 {
    padding-top: 18.10526rem;
    padding-bottom: 18.10526rem; }
  .large-padding-44 {
    padding-top: 18.52632rem;
    padding-bottom: 18.52632rem; }
  .large-padding-45 {
    padding-top: 18.94737rem;
    padding-bottom: 18.94737rem; }
  .large-padding-46 {
    padding-top: 19.36842rem;
    padding-bottom: 19.36842rem; }
  .large-padding-47 {
    padding-top: 19.78947rem;
    padding-bottom: 19.78947rem; }
  .large-padding-48 {
    padding-top: 20.21053rem;
    padding-bottom: 20.21053rem; } }

.small-align-right {
  justify-content: flex-end; }

.small-align-center {
  justify-content: center; }

.small-align-left {
  justify-content: flex-start; }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  .medium-align-right {
    justify-content: flex-end; }
  .medium-align-center {
    justify-content: center; }
  .medium-align-left {
    justify-content: flex-start; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  .large-align-right {
    justify-content: flex-end; }
  .large-align-center {
    justify-content: center; }
  .large-align-left {
    justify-content: flex-start; } }

ul.menu.small-horizontal {
  flex-wrap: wrap;
  flex-direction: row; }

ul.menu.small-vertical {
  flex-wrap: nowrap;
  flex-direction: column; }

@media print, screen and (min-width: 53.125em) and (min-width: 53.125em) {
  ul.menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  ul.menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column; } }

@media print, screen and (min-width: 96.875em) and (min-width: 96.875em) {
  ul.menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  ul.menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column; } }

body {
  min-height: 100%;
  max-width: 100vw;
  overflow-x: hidden; }
  body .white {
    background-color: #fefefe; }
  body .gray {
    background-color: #ededed; }
  body main {
    background-color: #fefefe;
    min-height: 100vh;
    flex: 1; }
  body.gray {
    background-color: #ededed; }
    body.gray main {
      background-color: #ededed; }
  body.black {
    background-color: #001526; }
    body.black main {
      background-color: #001526; }
  body.white {
    background-color: #fefefe; }
    body.white main {
      background-color: #fefefe; }
  body .off-canvas-content {
    min-height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column; }
  body section,
  body header {
    position: relative; }
    body section.gray,
    body header.gray {
      background-color: #ededed; }
      body section.gray main,
      body header.gray main {
        background-color: #ededed; }
    body section.black,
    body header.black {
      background-color: #001526; }
      body section.black main,
      body header.black main {
        background-color: #001526; }
    body section.white,
    body header.white {
      background-color: #fefefe; }
      body section.white main,
      body header.white main {
        background-color: #fefefe; }
  body img {
    width: 100%;
    height: auto; }
  body video {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer; }
  body .video-container {
    cursor: pointer; }
  body .grid-container.no-padding {
    padding-left: 0;
    padding-right: 0; }

._red {
  background-color: rgba(255, 0, 0, 0.2);
  border: dashed 1px rgba(255, 0, 0, 0.8); }

._green {
  background-color: rgba(0, 128, 0, 0.2);
  border: dashed 1px rgba(0, 128, 0, 0.8); }

._blue {
  background-color: rgba(0, 0, 255, 0.2);
  border: dashed 1px rgba(0, 0, 255, 0.8); }

._yellow {
  background-color: rgba(255, 255, 0, 0.2);
  border: dashed 1px rgba(255, 255, 0, 0.8); }

@font-face {
  font-family: 'SourceSansPro';
  src: url("../font/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansPro';
  src: url("../font/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Satisfy';
  src: url("../font/Satisfy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: cursive; }

span,
a {
  line-height: 1.5; }

address {
  font-style: normal !important;
  line-height: 1.5;
  margin-bottom: 1rem; }

cite {
  color: #ba2d2d;
  font-style: normal !important;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif; }

a,
.link {
  transition: color 300ms ease;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 3px;
  font-weight: bold;
  color: #ba2d2d; }
  a svg.link-arrow,
  .link svg.link-arrow {
    transform: translateX(0px) translateY(2px);
    transition-duration: 300ms;
    height: 0.94737rem;
    margin-right: 8px; }
    a svg.link-arrow path,
    a svg.link-arrow polyline,
    .link svg.link-arrow path,
    .link svg.link-arrow polyline {
      stroke: #ba2d2d; }
  a:hover,
  .link:hover {
    transition: color 300ms ease;
    color: #8c2222; }
    a:hover svg.link-arrow,
    .link:hover svg.link-arrow {
      transform: translateX(2px) translateY(2px); }
      a:hover svg.link-arrow path,
      a:hover svg.link-arrow polyline,
      .link:hover svg.link-arrow path,
      .link:hover svg.link-arrow polyline {
        stroke: #8c2222; }
    a:hover svg.hover path,
    a:hover svg.hover polyline,
    .link:hover svg.hover path,
    .link:hover svg.hover polyline {
      fill: #8c2222; }

.fake-link:hover {
  cursor: pointer; }

.text-light {
  color: #7b7b7b; }

p a,
h1 a,
h2 a,
h3 a,
h4 a {
  text-transform: none; }

p p,
h1 p,
h2 p,
h3 p,
h4 p {
  line-height: inherit; }

.subline {
  font-size: 1.26316rem;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: #ba2d2d;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  text-transform: uppercase; }

.sans {
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

h1, .h1 {
  line-height: 1.44;
  letter-spacing: normal; }

h2, .h2 {
  line-height: 1.42;
  letter-spacing: normal; }

h4, .h4 {
  font-weight: bold; }

blockquote p {
  font-family: "FFHertzWebProBold", "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif; }

@media screen and (max-width: 53.0625em) {
  .grid-container.small-full {
    padding-left: 0;
    padding-right: 0; }
  a,
  .link {
    font-size: 0.94737rem;
    letter-spacing: 1px; }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1.33; } }

@media print, screen and (min-width: 53.125em) {
  h1, .h1 {
    line-height: 1.4;
    letter-spacing: normal; }
  h2, .h2 {
    line-height: 1.38;
    letter-spacing: normal; }
  blockquote p {
    font-size: 1.10526rem; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-07 {
  0% {
    opacity: 0.7; }
  50% {
    opacity: 1; } }

@keyframes fade-out-07 {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.7; } }

@keyframes gradient-scroll {
  0% {
    background-position: 0% 0%; }
  50% {
    background-position: 100% 100%; }
  100% {
    background-position: 0% 0%; } }

@keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes rotate-45-in {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-45deg); } }

@keyframes rotate-45-out {
  0% {
    transform: rotate(-45deg); }
  100% {
    transform: rotate(0); } }

@keyframes slide-right-in {
  0% {
    transform: translateX(0px); }
  100% {
    transform: translateX(2px); } }

@keyframes slide-right-out {
  0% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0px); } }

@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes slide-out-bottom {
  0% {
    transform: translateY(0%); }
  100% {
    transform: translateY(100%); } }

@keyframes zoom-fade-in {
  0% {
    opacity: 0;
    transform: scale(1); }
  100% {
    opacity: 1;
    transform: scale(1.2); } }

@keyframes zoom-fade-out {
  0% {
    opacity: 1;
    transform: scale(1.2); }
  100% {
    opacity: 0;
    transform: scale(1); } }

@keyframes zoom-in {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

@keyframes zoom-out {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes zoom-in-gradient {
  0% {
    transform: scale(1);
    background-position: 0% 0%; }
  10% {
    transform: scale(1.1); }
  50% {
    background-position: 100% 100%; }
  100% {
    transform: scale(1.1);
    background-position: 0% 0%; } }

@keyframes grow {
  0% {
    width: 3%;
    left: -2%;
    right: 98%; }
  50% {
    width: 102%;
    left: -1%;
    right: -1%; }
  98% {
    width: 4%;
    right: -2%;
    left: 98%; }
  100% {
    width: 2%;
    right: -1%;
    left: 99%; } }

@keyframes vehicle-rotate-in {
  0% {
    transform: rotate(6deg);
    transform-origin: 200% 100%;
    opacity: 0.9; }
  100% {
    transform: rotate(0);
    opacity: 1;
    transform-origin: 200% 100%; } }

@keyframes vehicle-rotate-out {
  0% {
    transform-origin: 200% 100%;
    transform: rotate(0);
    opacity: 1; }
  100% {
    opacity: 0;
    transform: rotate(-6deg);
    transform-origin: 200% 100%; } }

@keyframes text-rotate-in {
  0% {
    transform: rotate(6deg);
    transform-origin: -200% 100%;
    opacity: 0.9; }
  100% {
    transform: rotate(0);
    transform-origin: -200% 100%;
    opacity: 1; } }

@keyframes text-rotate-out {
  0% {
    transform: rotate(0);
    transform-origin: -200% 100%;
    opacity: 1; }
  100% {
    transform: rotate(-6deg);
    transform-origin: -200% 100%;
    opacity: 0; } }

@keyframes slide-right {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(10px); } }

@keyframes slide-right-out {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

[data-aos="video-icon"] {
  transition-property: transform;
  transform: translateY(0%); }
  [data-aos="video-icon"].aos-animate {
    transform: translateY(50%); }

.button {
  cursor: pointer;
  background-repeat: no-repeat;
  padding: 0.85rem 1rem; }
  .button.hollow {
    color: #fefefe;
    background: transparent;
    border-color: #fefefe;
    border-width: 1px; }
    .button.hollow:hover {
      background-color: #fefefe;
      color: #001526 !important;
      border-color: #fefefe; }
    .button.hollow.black {
      background-color: transparent;
      color: #001526;
      border: solid 1px #001526; }
      .button.hollow.black:hover {
        background-image: linear-gradient(95deg, #ba2d2d, #406caf);
        color: #001526;
        border-color: transparent; }
  .button.gradient {
    background: linear-gradient(45deg, #ba2d2d, #406caf, #ba2d2d, #406caf, #ba2d2d);
    background-size: 400% 400%;
    animation: gradient-scroll 7s cubic-bezier(0.645, 0.045, 0.355, 1) reverse infinite;
    will-change: background-position;
    transform: translateZ(0);
    color: #fefefe;
    font-weight: bold; }
    @media print, screen and (min-width: 53.125em) {
      .button.gradient {
        font-size: 1.15789rem; } }
    .button.gradient:hover {
      color: #001526;
      animation-duration: 30s; }
  .button.black {
    background-color: #001526;
    color: #fefefe;
    border: none;
    border: 1px solid #001526; }
    .button.black:hover {
      animation-duration: 30s;
      background-color: #fefefe;
      color: #001526;
      border: 1px solid #001526; }
  .button.white {
    background-color: #fefefe;
    color: #001526;
    border: none; }
    .button.white:hover {
      background-color: #001526;
      animation: background-color;
      color: #ba2d2d; }
  .button.large {
    font-weight: bold; }
  @media print, screen and (min-width: 53.125em) {
    .button {
      font-size: 0.94737rem; } }

.main-nav {
  width: 100%;
  position: fixed;
  z-index: 100;
  background-color: #001526; }
  .main-nav .grid-container {
    padding: 0; }
    .main-nav .grid-container .top-bar a {
      color: inherit;
      text-transform: none;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      font-weight: normal; }
      .main-nav .grid-container .top-bar a:hover {
        color: #8c2222; }
    .main-nav .grid-container .top-bar .top-bar-center .logo-text,
    .main-nav .grid-container .top-bar .top-bar-right .logo-text {
      color: white;
      font-size: 24px !important;
      font-family: 'Satisfy', cursive; }
    .main-nav .grid-container .top-bar .top-bar-center .menu li a,
    .main-nav .grid-container .top-bar .top-bar-right .menu li a {
      transition: color 300ms ease;
      text-transform: none;
      font-size: 0.94737rem;
      line-height: 1.78;
      font-weight: normal;
      position: relative;
      min-width: 36px; }
      .main-nav .grid-container .top-bar .top-bar-center .menu li a svg,
      .main-nav .grid-container .top-bar .top-bar-right .menu li a svg {
        position: absolute;
        transition: fill 500ms ease;
        margin: auto;
        width: auto;
        height: 28px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
    .main-nav .grid-container .top-bar .top-bar-right {
      -webkit-box-flex: 0;
      flex: 0 0 auto; }
    .main-nav .grid-container .top-bar .top-bar-left {
      -webkit-box-flex: 0;
      flex: 0 0 auto; }
      .main-nav .grid-container .top-bar .top-bar-left a svg {
        width: auto;
        transition: fill 500ms ease;
        height: 3.42105rem; }
    .main-nav .grid-container .top-bar .menu a {
      transition-delay: 1s;
      transition: fill 500ms ease;
      color: #fefefe;
      padding: .7rem 0.5rem; }
      .main-nav .grid-container .top-bar .menu a:hover {
        color: #8c2222; }
      .main-nav .grid-container .top-bar .menu a.is-active {
        font-weight: bold !important; }
    .main-nav .grid-container .top-bar .top-bar-left .dropdown.menu li.is-dropdown-submenu-parent a {
      padding: 1rem 1rem; }
    .main-nav .grid-container .top-bar .top-bar-left .dropdown.menu li.is-dropdown-submenu-parent a::after {
      transition: border-color 500ms ease;
      border-color: #fefefe transparent transparent; }
    .main-nav .grid-container .top-bar .top-bar-left .dropdown.menu li.is-dropdown-submenu-parent a:hover::after {
      transition: border-color 500ms ease; }
    .main-nav .grid-container .top-bar .top-bar-left .dropdown.menu .is-dropdown-submenu {
      background-color: #fefefe;
      border: none; }
      .main-nav .grid-container .top-bar .top-bar-left .dropdown.menu .is-dropdown-submenu li a {
        color: #001526; }
        .main-nav .grid-container .top-bar .top-bar-left .dropdown.menu .is-dropdown-submenu li a:hover {
          transition: border-color 500ms ease;
          background-color: #ba2d2d; }
    .main-nav .grid-container .top-bar .top-bar-left .langswitcher {
      text-transform: uppercase; }
    .main-nav .grid-container .top-bar .top-bar-right .contact {
      border: solid 0.5px #fefefe; }

.off-canvas {
  background-color: #fefefe; }
  .off-canvas a {
    color: inherit;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: normal; }
    .off-canvas a:hover {
      color: #8c2222; }
    .off-canvas a:focus {
      outline: none; }
  .off-canvas .top-bar {
    flex-wrap: nowrap; }
    .off-canvas .top-bar .top-bar-left {
      flex: 1 1 auto;
      margin-right: auto; }
    .off-canvas .top-bar .top-bar-right {
      flex: 0 1 auto;
      margin-left: auto; }
    .off-canvas .top-bar .top-bar-left .logo,
    .off-canvas .top-bar .top-bar-right .logo {
      width: auto;
      height: 3.42105rem; }
  .off-canvas .menu-text li a {
    padding-left: 0 !important; }
  .off-canvas .footer-accordion a.accordion-title h2 {
    margin-top: 0; }

section.paragraph .content {
  position: relative; }
  section.paragraph .content .box {
    background: #fefefe; }
  section.paragraph .content .menu li a {
    padding-left: 0; }
  section.paragraph .content .text-container {
    position: relative; }
    section.paragraph .content .text-container h3.label {
      height: 6rem;
      width: 6rem;
      line-height: 6rem;
      border-radius: 50%;
      text-align: center;
      background-color: #001526;
      color: #fefefe;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%) rotate(-10deg);
      z-index: 5; }
    @media screen and (max-width: 53.0625em) {
      section.paragraph .content .text-container.padding-left-middle, section.paragraph .content .text-container.padding-right-middle {
        padding-top: 2rem; } }
    section.paragraph .content .text-container .text {
      z-index: 10;
      position: relative; }
    @media print, screen and (min-width: 53.125em) {
      section.paragraph .content .text-container blockquote {
        margin-left: 8%;
        margin-right: -8%; } }
    section.paragraph .content .text-container blockquote {
      margin-top: 2rem;
      margin-bottom: 2rem; }

section.paragraph .illustration-container {
  position: relative; }
  section.paragraph .illustration-container .illustration {
    z-index: 2;
    position: relative;
    transform: translateX(-50%); }
    section.paragraph .illustration-container .illustration svg {
      height: auto;
      width: 100%; }
  section.paragraph .illustration-container.right {
    left: 100%; }
    @media screen and (max-width: 53.0625em) {
      section.paragraph .illustration-container.right .illustration {
        transform: translateX(-25%); } }
  section.paragraph .illustration-container.left {
    right: 0; }
    @media screen and (max-width: 53.0625em) {
      section.paragraph .illustration-container.left .illustration {
        transform: translateX(-75%); } }

@media screen and (max-width: 53.0625em) {
  section.img-text-box.left, section.img-text-box.right, section.img-text-box.middle {
    padding-top: 3.5rem; } }

@media print, screen and (min-width: 53.125em) {
  section.img-text-box.left, section.img-text-box.right {
    padding-top: 6rem; }
  section.img-text-box.middle {
    padding-bottom: 6rem; } }

section.img-text-box .grid-container .box {
  position: relative; }
  section.img-text-box .grid-container .box .box-image, section.img-text-box .grid-container .box box-image-mobile {
    position: relative; }
  section.img-text-box .grid-container .box .illustration {
    position: absolute; }
    @media screen and (max-width: 53.0625em) {
      section.img-text-box .grid-container .box .illustration svg {
        height: 7rem;
        width: auto; } }
    @media print, screen and (min-width: 53.125em) {
      section.img-text-box .grid-container .box .illustration svg {
        height: 10rem;
        width: auto; } }
    section.img-text-box .grid-container .box .illustration.right {
      top: 0px;
      right: 0px;
      transform: translate(50%, -50%); }
    section.img-text-box .grid-container .box .illustration.left {
      top: 0px;
      transform: translate(-50%, -50%); }
    section.img-text-box .grid-container .box .illustration.middle-left {
      bottom: 0px;
      right: 0px;
      transform: translate(50%, 50%); }
    section.img-text-box .grid-container .box .illustration.middle-right {
      bottom: 0px;
      left: 0px;
      transform: translate(-50%, 50%); }

section.img-text-box .grid-container .grid-x .box-image {
  min-height: 400px;
  background-size: cover;
  background-position: center; }

section.img-text-box .grid-container .grid-x .box-image-mobile {
  height: 200px;
  background-size: cover;
  background-position: center; }

section.img-text-box .grid-container .grid-x .link svg .cls-1, section.img-text-box .grid-container .grid-x .link svg .cls-2 {
  fill: none !important; }

section.img-text-box .grid-container .grid-x .link svg polyline {
  stroke: #ba2d2d; }

section.img-text-box .grid-container .grid-x .link svg polyline:hover {
  stroke: #bf2417; }

section.img img {
  object-fit: cover;
  max-height: 600px;
  width: 100%; }

section.calendar .red {
  color: #ba2d2d; }

section.calendar .box-image img {
  height: 100%;
  width: 100%;
  object-fit: cover; }

section.calendar .calendar-entry .dropdown {
  display: none; }
  section.calendar .calendar-entry .dropdown.shown {
    display: flex; }
  section.calendar .calendar-entry .dropdown a {
    color: #001526;
    text-transform: none; }

section.calendar .calendar-entry .date h1 {
  font-size: 3.2rem; }

section.calendar .calendar-entry .info-toggle .open-up {
  display: flex; }
  section.calendar .calendar-entry .info-toggle .open-up.hidden {
    display: none; }

section.calendar .calendar-entry .info-toggle .close-down {
  display: none; }
  section.calendar .calendar-entry .info-toggle .close-down.shown {
    display: flex; }

section.calendar .calendar-entry .info-toggle svg.arrow-up {
  transform: rotate(-90deg); }

section.calendar .calendar-entry .info-toggle svg.arrow-down {
  transform: rotate(90deg); }

section.calendar .calendar-entry .info-toggle svg .cls-1,
section.calendar .calendar-entry .info-toggle svg .cls-2 {
  fill: none !important; }

section.calendar .calendar-entry .info-toggle svg polyline {
  stroke: #ba2d2d; }

section.calendar .calendar-entry .info-toggle svg polyline:hover {
  stroke: #bf2417; }

section.calendar-teaser .slider {
  margin-left: 0 !important;
  margin-left: -0.52632rem;
  margin-right: -0.52632rem; }
  @media print, screen and (min-width: 53.125em) {
    section.calendar-teaser .slider {
      margin-left: -0.78947rem;
      margin-right: -0.78947rem; } }
  section.calendar-teaser .slider a {
    color: inherit;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: normal; }
    section.calendar-teaser .slider a:hover {
      color: #8c2222; }
  section.calendar-teaser .slider .slick-slider {
    padding-bottom: 0px; }
  section.calendar-teaser .slider .slick-track {
    display: flex !important; }
    section.calendar-teaser .slider .slick-track .slide {
      height: inherit !important;
      padding-left: 0.52632rem;
      padding-right: 0.52632rem; }
      @media print, screen and (min-width: 53.125em) {
        section.calendar-teaser .slider .slick-track .slide {
          padding-left: 0.78947rem;
          padding-right: 0.78947rem; } }
      section.calendar-teaser .slider .slick-track .slide .content-container {
        height: 100%; }
        section.calendar-teaser .slider .slick-track .slide .content-container .content {
          padding-top: 0.52632rem;
          padding-left: 0.52632rem;
          padding-right: 0.52632rem;
          padding-bottom: 0.52632rem;
          background-color: #fefefe; }
          @media print, screen and (min-width: 53.125em) {
            section.calendar-teaser .slider .slick-track .slide .content-container .content {
              padding-top: 0.78947rem;
              padding-left: 0.78947rem;
              padding-right: 0.78947rem;
              padding-bottom: 0.78947rem; } }
      section.calendar-teaser .slider .slick-track .slide:focus {
        outline: none; }
  section.calendar-teaser .slider .slick-dotted {
    margin-bottom: 0px; }
  section.calendar-teaser .slider .slick-dots {
    display: flex;
    position: relative;
    bottom: 0px;
    justify-content: center;
    margin: 0;
    padding-top: 2rem;
    list-style-type: none; }
    section.calendar-teaser .slider .slick-dots li {
      margin: 0 0.25rem; }
    section.calendar-teaser .slider .slick-dots button {
      display: block;
      width: 0.9rem;
      height: 0.9rem;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: #fefefe;
      text-indent: -9999px; }
    section.calendar-teaser .slider .slick-dots li.slick-active button {
      background-color: #ba2d2d; }

section.dropdown .accordion #graphic-conatiner path.info-link {
  fill: #001526; }
  section.dropdown .accordion #graphic-conatiner path.info-link.is-active {
    fill: #406caf !important; }

section.dropdown .accordion .accordion-item {
  border-radius: 8px; }
  section.dropdown .accordion .accordion-item.is-active .accordion-title svg {
    transform: rotate(-90deg); }
  section.dropdown .accordion .accordion-item .accordion-title {
    color: #001526;
    text-transform: none;
    color: inherit;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: normal; }
    section.dropdown .accordion .accordion-item .accordion-title h2 {
      margin-top: 0.5rem; }
    section.dropdown .accordion .accordion-item .accordion-title:hover {
      color: #8c2222; }
    section.dropdown .accordion .accordion-item .accordion-title:hover svg polyline {
      stroke: #8c2222; }
    section.dropdown .accordion .accordion-item .accordion-title .open-up {
      display: flex; }
      section.dropdown .accordion .accordion-item .accordion-title .open-up.hidden {
        display: none; }
    section.dropdown .accordion .accordion-item .accordion-title .close-down {
      display: none; }
      section.dropdown .accordion .accordion-item .accordion-title .close-down.shown {
        display: flex; }
    section.dropdown .accordion .accordion-item .accordion-title svg {
      transition: color 300ms ease;
      transform: rotate(90deg); }
      section.dropdown .accordion .accordion-item .accordion-title svg .cls-1,
      section.dropdown .accordion .accordion-item .accordion-title svg .cls-2 {
        fill: none !important; }
  section.dropdown .accordion .accordion-item .accordion-content {
    display: none; }

section.sliding-boxes .slick-track {
  display: flex !important; }

section.sliding-boxes .slick-slide {
  height: inherit !important; }

section.sliding-boxes .slide svg {
  width: 100%;
  height: auto; }

section.sliding-boxes .slide .slide-img {
  height: 280px;
  background-size: cover;
  background-position: center; }

@media screen and (max-width: 53.0625em) {
  section.sliding-boxes {
    background-color: #ededed !important; } }

section.sliding-boxes .slick-dotted {
  margin-bottom: 0px; }

section.sliding-boxes .slick-slider {
  padding-bottom: 0px; }

section.sliding-boxes .slick-dots {
  display: flex;
  position: relative;
  bottom: 0px;
  justify-content: center;
  margin: 0;
  padding-top: 2rem;
  list-style-type: none; }
  section.sliding-boxes .slick-dots li {
    margin: 0 0.25rem; }
  section.sliding-boxes .slick-dots button {
    display: block;
    width: 0.9rem;
    height: 0.9rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #fefefe;
    text-indent: -9999px; }
  section.sliding-boxes .slick-dots li.slick-active button {
    background-color: #ba2d2d; }

section.map #map2 {
  z-index: 0;
  height: 600px; }

section.map .leaflet-control-attribution {
  font-size: 10px !important; }
  section.map .leaflet-control-attribution a {
    font-size: 10px !important; }

section.map .leaflet-popup-content-wrapper {
  border-radius: 3px;
  font-family: "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif; }

section.box-milestones .milestone-box {
  position: relative; }
  @media print, screen and (min-width: 53.125em) {
    section.box-milestones .milestone-box {
      -webkit-box-shadow: 11px 11px 0px 0px #f5ab95;
      -moz-box-shadow: 11px 11px 0px 0px #f5ab95;
      box-shadow: 11px 11px 0px 0px #f5ab95; } }

section.box-milestones .illustration {
  position: absolute; }
  section.box-milestones .illustration.top-left {
    transform: translate(-50%, -50%) rotate(45deg);
    top: 0;
    left: 0; }
    section.box-milestones .illustration.top-left svg {
      width: 16rem; }
  section.box-milestones .illustration.middle-right {
    transform: translate(50%, -50%) rotate(90deg);
    top: 0;
    right: 0; }
    section.box-milestones .illustration.middle-right svg {
      width: 16rem; }

.footer-accordion a {
  color: inherit;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: normal; }
  .footer-accordion a:hover {
    color: #8c2222; }

.footer-accordion .accordion-content {
  display: none; }

.footer-accordion .is-active svg.arrow {
  transform: rotate(-90deg) !important; }

.footer-accordion a.accordion-title {
  color: inherit;
  text-transform: none;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: normal; }
  .footer-accordion a.accordion-title:hover {
    color: #8c2222; }
  .footer-accordion a.accordion-title:hover svg polyline {
    stroke: #8c2222; }
  .footer-accordion a.accordion-title h2 {
    margin-top: 0.5rem; }
  .footer-accordion a.accordion-title svg.arrow {
    transform: rotate(90deg); }

section.product-list .artist-img {
  height: 400px;
  background-color: #ba2d2d;
  background-size: cover;
  background-position: center; }

section.product-list .sheet-img {
  background-color: white;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.07); }

section.product-list audio {
  width: 100%; }

@media screen and (max-width: 53.0625em) {
  section.product-list .artist-img {
    height: 300px; } }

header.header .header-conatiner .img-video-container {
  height: 100vh;
  max-height: 800px;
  position: relative; }
  header.header .header-conatiner .img-video-container .video-container,
  header.header .header-conatiner .img-video-container .header-image {
    overflow-y: hidden;
    height: 100%; }
    header.header .header-conatiner .img-video-container .video-container img,
    header.header .header-conatiner .img-video-container .header-image img {
      object-fit: cover;
      width: 100%;
      height: 100%; }

header.header .header-conatiner .overlay-container {
  position: relative;
  transform: translateY(-50%); }
  header.header .header-conatiner .overlay-container .overlay {
    position: relative; }
    header.header .header-conatiner .overlay-container .overlay svg {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    header.header .header-conatiner .overlay-container .overlay .content {
      position: relative;
      z-index: 2; }
    header.header .header-conatiner .overlay-container .overlay .links {
      position: absolute;
      top: 100%; }

header.header .header-conatiner .illustration {
  width: 100%;
  transform: translateX(-25%); }
  header.header .header-conatiner .illustration svg {
    width: 100%;
    height: auto; }

@media screen and (max-width: 53.0625em) {
  header.header .header-conatiner .overlay-container .overlay,
  header.header .header-conatiner .overlay-container .links {
    padding-left: 9.0909090909%;
    padding-right: 9.0909090909%; } }

.footer {
  width: 100%;
  background-color: #ededed; }
  .footer a {
    color: inherit;
    text-transform: none;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: normal; }
    .footer a:hover {
      color: #8c2222; }
  .footer .footer-subline-mobile {
    font-size: 14px; }
    .footer .footer-subline-mobile svg {
      height: 20px; }
  .footer .footer-subline {
    border-top: 1px solid #001526; }
    .footer .footer-subline .logo-text {
      font-size: 24px !important;
      font-family: 'Satisfy', cursive; }
  .footer .footer-content {
    background-color: #fefefe; }
    .footer .footer-content .kuhkopf svg {
      width: 100%; }
  .footer .contact .grid-container {
    position: relative;
    color: #fefefe; }
    .footer .contact .grid-container .hollow {
      transition-delay: 1s;
      transition: background-image 500ms ease; }
      .footer .contact .grid-container .hollow:hover {
        transition: background-image 200ms ease;
        background-image: linear-gradient(95deg, #ba2d2d, #406caf);
        border-color: transparent;
        color: #fefefe; }
    .footer .contact .grid-container .content {
      position: relative;
      z-index: 20; }
  .footer .contact svg.overlay {
    position: absolute;
    right: 0px;
    top: -50px; }
    .footer .contact svg.overlay path {
      fill: #8a8a8a; }
  .footer .footer-menu {
    padding-top: 1.89474rem;
    padding-bottom: 1.89474rem;
    background-color: #fefefe; }
    .footer .footer-menu svg {
      width: 100%;
      height: auto; }
    .footer .footer-menu .menu li a {
      color: #001526; }
      .footer .footer-menu .menu li a:hover {
        color: #ba2d2d; }
  @media screen and (max-width: 53.0625em) {
    .footer {
      height: auto; }
      .footer .grid-container {
        padding: 0; }
      .footer .contact div {
        background-color: #8a8a8a; }
        .footer .contact div h2,
        .footer .contact div p,
        .footer .contact div a {
          max-width: 83.3333333333%; }
        .footer .contact div.small-green {
          background-color: #ba2d2d; }
        .footer .contact div .content {
          padding-left: 1rem;
          padding-right: 1rem; }
      .footer svg.overlay {
        display: none; }
      .footer .footer-menu {
        padding-bottom: 0; }
        .footer .footer-menu .menu {
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center; }
        .footer .footer-menu .gray {
          background-color: #ededed; } }
  .footer .mobile-ilustration svg {
    width: 100%; }
  .footer .accordion-content {
    display: none; }
  .footer .is-active svg.arrow {
    transform: rotate(-90deg) !important; }
  .footer .accordion-title {
    text-transform: none; }
    .footer .accordion-title h2 {
      margin-top: 0.5rem; }
    .footer .accordion-title svg.arrow {
      transform: rotate(90deg); }
    .footer .accordion-title svg .cls-1,
    .footer .accordion-title svg .cls-2 {
      fill: none !important; }
  @media print, screen and (min-width: 53.125em) {
    .footer {
      background-color: #ededed; }
      .footer .contact {
        background-color: #8a8a8a; }
        .footer .contact .grid-container {
          padding-bottom: 2.10526rem;
          padding-top: 2rem; }
      .footer .footer-menu .menu {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding-right: 0.5rem; } }

section.lead {
  background: #CB356B;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #BD3F32, #CB356B);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #BD3F32, #CB356B);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-color: #ededed;
  margin-bottom: 4rem; }
  section.lead .headline {
    text-transform: uppercase; }
  @media screen and (max-width: 53.0625em) {
    section.lead .grid-container {
      padding-left: 1rem;
      padding-bottom: 2rem; } }
  section.lead .highlight {
    color: #ba2d2d; }
  @media print, screen and (min-width: 53.125em) {
    section.lead h1 {
      font-family: 'Satisfy', cursive;
      font-size: 80px; } }
  section.lead .sub-title {
    color: white; }
