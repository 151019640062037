




// padding and margin
$grid-margin-count: 48;
$grid-base-margin: 8px;

$spacing-types: (margin, padding) !default;
$spacing-dir: (top, bottom, right, left) !default;


@include -zf-each-breakpoint {
    @include breakpoint($-zf-size) {
        @each $spacing in $spacing-types {
            @each $dir in $spacing-dir {
                @for $i from 0 through $grid-margin-count {
                    .#{$-zf-size}-#{$spacing}-#{$dir}-#{$i} {
                        #{$spacing}-#{$dir}: rem-calc($i * $grid-base-margin);
                    }
                }
            }
            @for $i from 0 through $grid-margin-count {
                .#{$-zf-size}-#{$spacing}-#{$i} {
                    #{$spacing}-top: rem-calc($i * $grid-base-margin);
                    #{$spacing}-bottom: rem-calc($i * $grid-base-margin);
                }
            }
        }
    }
}

// horizontal-alignment
$align-types: (right, center, left) !default;
@include -zf-each-breakpoint {
    @include breakpoint($-zf-size) {
        @each $align-type in $align-types {
            .#{$-zf-size}-align-#{$align-type} {
                @include flex-align($x: $align-type);
            }
        }
    }
}

//menu-direction
$directions: (horizontal, vertical) !default;

@include -zf-each-breakpoint {
    @include breakpoint($-zf-size) {
        @each $dir in $directions {
            ul.menu.#{$-zf-size}-#{$dir} {
                @include menu-direction($dir);
            }
        }
    }
}
