section.map {

    #map2{
        z-index: 0;
        height:600px;
    }

    .leaflet-control-attribution{
        font-size: 10px !important;

        a {
            font-size: 10px !important;
        }
    }
    .leaflet-popup-content-wrapper{
        border-radius: 3px;
        font-family: $body-font-family;
    }

}
