.off-canvas {
    background-color: $white;

    a {
        @include normal-anchor();

        &:focus {
            outline: none;
        }
    }

    .top-bar {
        @include top-bar-unstack;

        .top-bar-left,
        .top-bar-right {

            .logo {
                width: auto;
                height: rem-calc(65);
            }
        }
    }

    .menu-text {
        li {
            a {
                padding-left: 0 !important;
            }
        }
    }

    .footer-accordion {
        a.accordion-title {
            h2 {
                margin-top: 0;
            }
        }
    }
}